import * as React from "react";
import {useEffect} from "react";
import 'date-fns';
import {Button, Chip} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";

import {useDispatch, useSelector} from "react-redux";
import {State} from "../reducer";
import Grid from "@material-ui/core/Grid";
import {Autocomplete} from "@material-ui/lab";
import {getRuns} from "../action/ouvrageAction";
import {updateRunNumbersToHide} from "../epic/ParametrageEpic";
import {notifyError, notifySuccess} from "../action/notification.actions";
import {axiosInstance} from "../store";


interface Props {
    defaultRunNumbersToHide: number[]
}

export const RunParameter: React.FC<Props> = (props) => {
    const dispatch = useDispatch();
    const auth = useSelector(({auth}: State) => auth);

    const availableRuns = useSelector(({ouvrages}: State) => ouvrages.runs);
    useEffect(() => {
            dispatch(getRuns(false));
        }, [dispatch]
    )
    const [value, setValue] = React.useState(props.defaultRunNumbersToHide);

    useEffect(() => {
            setValue(props.defaultRunNumbersToHide)
        }, [props.defaultRunNumbersToHide]
    )

    const saveRunNumbers = () => {
        updateRunNumbersToHide(axiosInstance, value, auth.token)
            .then(() => {
                dispatch(notifySuccess("La configuration a été mis à jour."))
            })
            .catch(() => {
                dispatch(notifyError("Une erreur interne est survenue.", true))
            });
    };

    return (
        <>
            <tr>
                <td>
                    <label>Numéros des RUN à masquer</label>
                </td>
                <td colSpan={3}>
                    <Autocomplete
                        disableCloseOnSelect={true}
                        multiple
                        id="run-numbers-autocomplete"
                        value={value}
                        onChange={(event, newValue) => {
                            setValue(
                                [...newValue]
                            );
                        }}
                        options={availableRuns}
                        renderTags={(tagValue, getTagProps) =>
                            tagValue.map((option, index) => (
                                <Chip
                                    label={option}
                                    {...getTagProps({index})}
                                />
                            ))
                        }
                        renderInput={(params) => (
                            <TextField {...params} />
                        )}
                    />
                </td>
                <Grid container direction="row" justify="flex-end">
                    <Button variant="contained" color="primary" className="button-save"
                            style={{marginTop: 19, marginLeft: 15}}
                            onClick={saveRunNumbers}>
                        Enregistrer
                    </Button>
                </Grid>
            </tr>
        </>
    )
}
