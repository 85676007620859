import * as React from "react";
import {useState} from "react";
import 'date-fns';
import TextField from "@material-ui/core/TextField";
import {Autocomplete} from "@material-ui/lab";
import {useDispatch, useSelector} from "react-redux";
import {State} from "../../reducer";
import Grid from "@material-ui/core/Grid";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {Marque} from "../../types";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import {green} from "@material-ui/core/colors";

import {
    attachMarqueEditorialeToAdGroup,
    dettachMarqueEditorialeToAdGroup,
    getMarqueEditorialForAdGroup,
    getMarqueEditorialForAdGroupSuccess
} from '../../action/adGroupAction'
import makeStyles from "@material-ui/core/styles/makeStyles";
import {createStyles, Fab} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";

interface Props {
    marqueCollections: Marque[],
    groups: string[]
}

const useStyles = makeStyles(() =>
    createStyles({
        scroll: {
            height: 400,
            overflowY: 'scroll'
        }
    }),
);

export const MarqueGroupSelection: React.FC<Props> = (props) => {
    const {marqueCollections, groups} = props;
    const classes = useStyles();

    const marqueForAdGroup = useSelector(({adGroup}: State) => adGroup.marqueEditoriales);

    const [selectedGroup, setSelectedGroup] = useState<string | null>(null);
    const dispatch = useDispatch()

    const [isAdded, setAddred] = useState(false);

    return (
        <>
            <Grid
                container style={{marginTop: 37}}
                direction="row"
            >
                <Autocomplete style={{width: 380}}
                    options={groups}
                    getOptionLabel={(option) => option}
                    onChange={(event: any, newValue: string | null) => {
                        if (newValue === null) {
                            dispatch(getMarqueEditorialForAdGroupSuccess([]))
                        } else {
                            dispatch(getMarqueEditorialForAdGroup(newValue))
                        }
                        setSelectedGroup(newValue)
                    }}
                    renderInput={(params) => <TextField {...params} label="Groupe" variant="outlined"/>}
                />

            </Grid>
            <Grid
                container style={{marginTop: 15}}
                direction="row"
            >

                {selectedGroup &&
                <Grid xs={12} className={classes.scroll}>
                    <Table style={{marginTop: 15}}>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{width: 300}}>Marque éditoriale</TableCell>
                                <TableCell align={"center"}>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>

                                <TableCell>
                                    <Autocomplete
                                                  options={marqueCollections}
                                                  getOptionLabel={(option) => option.label}
                                                  onChange={(event: any, newValue: Marque | null) => {
                                                      if (newValue !== null) {
                                                          dispatch(attachMarqueEditorialeToAdGroup({
                                                              marqueId: newValue.id,
                                                              adGroup: selectedGroup
                                                          }))
                                                          setAddred(true)
                                                      }
                                                  }}

                                                  renderInput={(params) => <TextField {...params} onChange={() => setAddred(false)} label="Ajouter" variant="outlined"/>}
                                    />
                                </TableCell>
                                <TableCell align={"center"}>
                                    {isAdded && <Tooltip title={"La marque a été ajoutée dans le groupe"}>
                                        <Fab size={"small"}
                                             aria-label="save"
                                             style={{
                                                 cursor: "default",
                                                 background: green[500]
                                             }}
                                        >
                                            <CheckIcon/>
                                        </Fab>
                                    </Tooltip>}
                                </TableCell>
                            </TableRow>
                            {marqueForAdGroup.map(x =>
                                <TableRow>

                                    <TableCell>
                                        {x.label}
                                    </TableCell>


                                    <TableCell align={"center"}>
                                        <label htmlFor="icon-button-file">
                                            <IconButton color="primary" aria-label="Détacher" component="span"
                                                        onClick={() => {
                                                            if (selectedGroup !== null)
                                                                dispatch(dettachMarqueEditorialeToAdGroup({
                                                                    marqueId: x.id,
                                                                    adGroup: selectedGroup
                                                                }))
                                                        }}>
                                                <ClearIcon/>
                                            </IconButton>
                                        </label>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>

                </Grid>
                }

            </Grid>
        </>
    )
}
