import * as React from "react";
import {Route} from "react-router-dom";
import {Parameter} from "./Parameter";
import {OuvrageList} from "./OuvrageList";
import {Router} from "react-router";
import {history} from "../store";
import Header from "./Header";

export const RootConfig: React.FC = (props) => {
    return (
        <Router history={history}>
            <Header/>
            <Route exact path="/" component={OuvrageList}/>
            <Route exact path="/parametre" component={Parameter}/>
        </Router>
    )
};
