import * as React from "react";
import {useEffect} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {createStyles, Theme} from '@material-ui/core/styles';
import makeStyles from "@material-ui/core/styles/makeStyles";
import 'date-fns';
import {KeyboardDatePicker, MuiPickersUtilsProvider,} from '@material-ui/pickers';
import DateFnsUtils from "@date-io/date-fns";
import {MarqueOrDivisionFilter} from "./MarqueOrDivisionFilter";
import {Node, Ouvrage} from "../types";
import {useDispatch, useSelector} from "react-redux";
import {State} from "../reducer";
import {getMarqueEditorial, getDivisions} from "../action/marqueEditorialAction";
import {getMevDates, updateSearchQueryParam} from "../action/ouvrageAction";
import {readQueryParams, searchParam} from "../epic/searchEpic";
import {RunFilter} from "./RunFilter";
import {EtatFilter} from "./EtatFilter";
import {CycladeFilter} from "./CycladeFilter";
import Button from '@material-ui/core/Button';


interface Props {
    ouvrages: Ouvrage[]
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        formControl: {
            margin: '2px 0',
        },
        checkBox: {
            padding: '0 .5rem 0 1rem',
            fontSize: '10px'
        },
        buttonFilter: {
            marginLeft: 50
        }
    }),
);

export const AdvancedSearch: React.FC<Props> = (props) => {
    const classes = useStyles();

    const mevDates = useSelector(({ouvrages}: State) => ouvrages.mevDates);
    const dispatch = useDispatch();


    useEffect(() => {
        dispatch(getMevDates());
    }, [dispatch]);

    const divisionList = useSelector(({marqueEditorial}: State) => marqueEditorial.divisions).map(x => {
        return {
            value: x,
            label: x,
            className: '',
        } as Node
    });

    const marqueList = useSelector(({marqueEditorial}: State) => marqueEditorial.marqueEditorial).map(x => {
        return {
            value: x.id,
            label: x.label,
            className: '',
            adGroup: x.groupe
        } as Node
    });

    const collectionsLoading = useSelector(({marqueEditorial}: State) => marqueEditorial.collectionsLoading);

    const history = useHistory();

    useEffect(() => {
            dispatch(getDivisions());
            dispatch(getMarqueEditorial());
        }, [dispatch]
    )


    const searchLocation = useLocation().search;
    const queryFilters = readQueryParams(searchParam, searchLocation);

    const startMev = queryFilters.startDate || mevDates.mevStartDate;
    const endMev = queryFilters.endDate || mevDates.mevEndDate;

    function isValidDate(d: any) {
        return d instanceof Date && !isNaN(d.getTime());
    }

    const handleStartMevChange = (date: Date | null) => {
        if (date != null && isValidDate(date)) {
            dispatch(updateSearchQueryParam({
                field: `startDate`, value: date.toISOString().split('T')[0]
            }))
        }
    };

    const handleEndMevChange = (date: Date | null) => {
        if (date !== null) {
            dispatch(updateSearchQueryParam({
                field: `endDate`, value: date.toISOString().split('T')[0]
            }))
        }
    };

    return (
        <div className="advanced-search">

            <div>
                <Button variant="outlined" color="primary" size={"small"} onClick={() => {
                    history.push('/');
                }}
                        className={classes.buttonFilter}>
                    Réinitialiser
                </Button>
            </div>

            <div className="search-date">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <div className="date-picker">
                        <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="normal"
                            label="MEV de début"
                            value={startMev}
                            onChange={handleStartMevChange}
                            autoOk
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </div>
                    <div className="date-picker">
                        <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="normal"
                            label="MEV de fin"
                            value={endMev}
                            autoOk
                            onChange={handleEndMevChange}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </div>
                </MuiPickersUtilsProvider>
            </div>
            
            <MarqueOrDivisionFilter label="Marque éditoriale" field="marqueEditoriale" list={marqueList} className={classes.formControl} collectionsLoading={collectionsLoading}/>
            <MarqueOrDivisionFilter label="Division" field="division" list={divisionList} className={classes.formControl} collectionsLoading={collectionsLoading}/>

            <RunFilter/>
            <EtatFilter/>
            <CycladeFilter type={'cyclade'}/>
            <CycladeFilter type={'elib'}/>
        </div>
    )
};
