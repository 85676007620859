import * as React from "react";
import {useEffect, useState} from "react";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import {Button, ButtonGroup, createStyles, FormControlLabel} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Divider from "@material-ui/core/Divider";
import Checkbox from "@material-ui/core/Checkbox";
import {
    generateCompelementThumbnail,
    saveFtpParamAction,
    saveSendEmailActiveAction,
    saveThumbnailBasePathAction,
    stopGenerationOfCompelementThumbnail
} from "../epic/ParametrageEpic";
import {MailParams} from "../types";
import {useDispatch, useSelector} from "react-redux";
import {State} from "../reducer";
import {getFtp, getParams} from "../action/parametrageAction";
import HighlightOff from "@material-ui/icons/HighlightOff";
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from "@material-ui/icons/Info";
import {notifyError, notifySuccess} from "../action/notification.actions";
import {axiosInstance} from "../store";


export const SystemParameter: React.FC = () => {


    const ftpParamsStore = useSelector(({parametrage}: State) => parametrage.ftp);
    const params = useSelector(({parametrage}: State) => parametrage.params);
    const auth = useSelector(({auth}: State) => auth);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getParams());
        dispatch(getFtp());
    }, [dispatch]);

    const useStyles = makeStyles(() =>
        createStyles({
            buttonSave: {
                margin: '.5rem 0 .5rem 5rem',
                padding: '.35rem 2.5rem .35rem 2.5rem'
            },
            textField2: {
                width: '51%',
                margin: '.5rem',
            },
            textField: {
                width: '30%',
                margin: '.5rem',
            },
            formControl: {
                margin: '0 2rem 0 1rem',
                minWidth: 300,
                maxWidth: 300,
            },
            chips: {
                display: 'flex',
                flexWrap: 'wrap',
            },
            chip: {
                margin: 2,
            },
            select: {
                width: '15rem',
                borderBlock: '1px',
                borderRadius: '1px'
            }
        }),
    );
    const classes = useStyles();

    const parametrageStatus = useSelector(({parametrage}: State) => parametrage.status);

    const [ftpUrl, setFtpUrl] = useState(ftpParamsStore?.ftpUrl ?? '');
    const [ftpPort, setFtpPort] = useState(ftpParamsStore?.ftpPort ?? 21);
    const [ftpLogin, setFtpLogin] = useState(ftpParamsStore?.ftpLogin ?? '');
    const [ftpPassword, setFtpPassword] = useState(ftpParamsStore?.ftpPassword ?? '');
    const [ftpBaseDirectory, setFtpBaseDirectory] = useState(ftpParamsStore?.ftpBaseDirectory ?? '');
    const [thumbnailBaseDirectory, setThumbnailBaseDirectory] = useState(params?.basePathThumbnail ?? '');

    const [ftpUrlError, setFtpUrlError] = useState(false);
    const [ftpPortError, setFtpPortError] = useState(false);
    const [ftpLoginError, setFtpLoginError] = useState(false);
    const [ftpPasswordError, setFtpPasswordError] = useState(false);
    const [ftpFileNameError, setFtpFileNameError] = useState(false);
    const [thumbnailBaseDirectoryError, setThumbnailBaseDirectoryError] = useState(false);

    useEffect(() => {
        if (ftpParamsStore && parametrageStatus === 'LOADED') {
            setFtpUrl(ftpParamsStore.ftpUrl)
            setFtpPort(ftpParamsStore.ftpPort)
            setFtpLogin(ftpParamsStore.ftpLogin)
            setFtpPassword(ftpParamsStore.ftpPassword)
            setFtpBaseDirectory(ftpParamsStore.ftpBaseDirectory)
        }
    }, [parametrageStatus, ftpParamsStore])

    const handleChangeUrl = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFtpUrl(event.target.value);
    };
    const handleChangePort = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFtpPort(parseInt(event.target.value));
    };
    const handleChangeLogin = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFtpLogin(event.target.value);
    };
    const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFtpPassword(event.target.value);
    };

    const handleChangeFtpFileName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFtpBaseDirectory(event.target.value);
    };

    const handleChangeThumbnailPath = (event: React.ChangeEvent<HTMLInputElement>) => {
        setThumbnailBaseDirectory(event.target.value);
    };


    const [sendEmailActive, setSendEmailActive] = useState(params?.status ?? false);
    const [mailUrl, setMailUrl] = useState(params?.smtpUrl ?? '');
    const [mailLogin, setMailLogin] = useState(params?.smtpUser ?? '');
    const [mailPassword, setMailPassword] = useState(params?.smtpPassword ?? '');
    const [mailFrom, setMailFrom] = useState(params?.emailFrom ?? '');
    const [mailErrorFileEmail, setMailErrorFileEmail] = useState(params?.errorFileEmail ?? '')

    useEffect(() => {
        if (params && parametrageStatus === 'LOADED') {
            setMailUrl(params.smtpUrl)
            setMailLogin(params.smtpUser)
            setMailPassword(params.smtpPassword)
            setMailFrom(params.emailFrom)
            setSendEmailActive(params.status)
            setThumbnailBaseDirectory(params?.basePathThumbnail ?? '')
            setMailErrorFileEmail(params.errorFileEmail)
        }
    }, [parametrageStatus, params])

    const handleChangeSendEmailActive = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSendEmailActive(event.target.checked);
    };
    const handleChangeMailPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMailPassword(event.target.value);
    };
    const handleChangeMailFrom = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMailFrom(event.target.value);
    };
    const handleChangeMailLogin = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMailLogin(event.target.value);
    };
    const handleChangeMailUrl = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMailUrl(event.target.value);
    };
    const handleChangeMailErrorFileEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMailErrorFileEmail(event.target.value);
    };

    const saveSendEmailActive = () => {
        let params: MailParams = {
            status: sendEmailActive,
            smtpUrl: mailUrl,
            smtpUser: mailLogin,
            smtpPassword: mailPassword,
            emailFrom: mailFrom,
            errorFileEmail: mailErrorFileEmail
        };
        saveSendEmailActiveAction(axiosInstance, params, auth.token)
            .then(() => {
                dispatch(notifySuccess("La configuration a été mis à jour.", true))
            })
            .catch(() => {
                dispatch(notifyError("Une erreur interne est survenue.", true))
            });
    };

    const setError = (messageCompl?: string) => {
        dispatch(notifyError("Un champs est manquant ou mal formaté. Veuillez remplir tous les champs obligatoires." + (messageCompl ? `<br> ${messageCompl}` : ''), true));
    }

    const initError = () => {
        setFtpUrlError(false)
        setFtpLoginError(false)
        setFtpPasswordError(false)
        setFtpPortError(false)
        setFtpFileNameError(false)
    }

    const stopThumbnailGeneration = () => {
        stopGenerationOfCompelementThumbnail(axiosInstance, auth.token).then(() => {
            dispatch(getParams());
        }).catch(() => {
            dispatch(notifyError("Une erreur interne est survenue.", true))
        });
    }

    const generateThumbnail = () => {
        generateCompelementThumbnail(axiosInstance, auth.token).then(() => {
            dispatch(notifySuccess("La génération est en cours.", true))
            dispatch(getParams());
        }).catch(() => {
            dispatch(notifyError("Une erreur interne est survenue.", true))
        });
    }

    const saveBasePath = () => {
        setThumbnailBaseDirectoryError(false)

        if (thumbnailBaseDirectory && !(thumbnailBaseDirectory.match("^/|(/[\\w-]+)+$")
            || thumbnailBaseDirectory.match("^[a-zA-Z]:\\\\(((?![<>:\"/\\\\|?*]).)+((?<![ .])\\\\)?)*$"))) {
            dispatch(notifyError("Le dossier doit être valide.", true))
            return;
        }

        saveThumbnailBasePathAction(axiosInstance, thumbnailBaseDirectory ?? '', auth.token)
            .then(() => {
                dispatch(notifySuccess("La configuration a été mis à jour.", true))
            })
            .catch(() => {
                dispatch(notifyError("Une erreur interne est survenue.", true))
            });
        setThumbnailBaseDirectoryError(false)

    };

    const saveFtp = () => {
        initError();
        let error = false;
        let messageCompl = ''
        if (!ftpUrl) {
            error = true;
            setFtpUrlError(true);
        }
        if (!ftpLogin) {
            error = true;
            setFtpLoginError(true);
        }
        if (!ftpPassword) {
            error = true;
            setFtpPasswordError(true);
        }
        if (ftpPort === null || isNaN(ftpPort) || ftpPort < 0) {
            error = true;
            setFtpPortError(true);
        }
        if (!ftpBaseDirectory || !ftpBaseDirectory.match("^/|(/[\\w-]+)+$")) {
            setFtpFileNameError(true);
            error = true;
            messageCompl = "Le chemin du dossier n'est pas valide.";
        }

        if (error) {
            setError(messageCompl);
            return;
        }
        const ftp = {
            ftpUrl: ftpUrl,
            ftpLogin: ftpLogin,
            ftpPassword: ftpPassword,
            ftpPort: ftpPort,
            ftpBaseDirectory: ftpBaseDirectory
        };
        saveFtpParamAction(axiosInstance, ftp, auth.token)
            .then(() => {
                dispatch(notifySuccess("La configuration a été mis à jour.", true))
            })
            .catch(() => {
                dispatch(notifyError("Une erreur interne est survenue.", true))
            });
        setFtpUrlError(false);
        setFtpLoginError(false);
        setFtpPasswordError(false);
        setFtpPortError(false);
        setFtpFileNameError(false);
    };


    const launchImportCyclade = () => {
        return axiosInstance.get("/ouvrages/import", {
                headers: {
                    'Authorization': 'Bearer ' + auth.token
                },
            }
        )
    }

    return (
        <div>
            <h3 className="h3-title">Paramétrage Système</h3>
            <Button variant="contained" color="primary" className="button-save" hidden={true}
                    onClick={launchImportCyclade}>
                Lancer l'import cyclade manuellement
            </Button>
            <div className="param-content-system">
                <h4> Connexion FTP Import Cyclade </h4>

                <div className="param-block-system">
                    <label className="text-param-system">URL </label>
                    <TextField error={ftpUrlError} id="outlined-basic" variant="outlined"
                               className={classes.textField} value={ftpUrl}
                               onChange={handleChangeUrl}/>

                    <label className="text-param-system">Port</label>
                    <TextField error={ftpPortError} id="outlined-basic" variant="outlined" type="number"
                               className={classes.textField}
                               value={ftpPort}
                               onChange={handleChangePort}/>
                </div>
                <div className="param-block-system">
                    <label className="text-param-system">Login </label>
                    <TextField error={ftpLoginError} id="outlined-basic" variant="outlined"
                               className={classes.textField}
                               value={ftpLogin}
                               onChange={handleChangeLogin}/>

                    <label className="text-param-system">Password </label>
                    <TextField error={ftpPasswordError} id="outlined-basic" variant="outlined"
                               type="password"
                               className={classes.textField}
                               value={ftpPassword}
                               onChange={handleChangePassword}/>
                </div>
                <div className="param-block-system">
                    <label className="text-param-system">Dossier racine</label>
                    <TextField error={ftpFileNameError} id="outlined-basic" variant="outlined"
                               className={classes.textField2}
                               value={ftpBaseDirectory} fullWidth={true}
                               onChange={handleChangeFtpFileName}/>
                </div>
                <Grid container direction="row" justify="flex-end">
                    <Button variant="contained" color="primary" className="button-save" onClick={saveFtp}>
                        Enregistrer
                    </Button>
                </Grid>
            </div>
            <div className="divider">
                <Divider variant="middle"/>
            </div>
            <div className="param-content-system">
                <h4> Paramétrage des alertes par email </h4>
                <table className="table-param-3">
                    <tbody>
                    <tr>
                        <td style={{width: '30%'}}>
                            <label className="label-input">Activer / Desactiver </label>
                        </td>
                        <td>
                            <FormControlLabel
                                control={<Checkbox checked={sendEmailActive}
                                                   onChange={(event) => handleChangeSendEmailActive(event)}/>}
                                label=""
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <label className="label-input">Adresse du
                                serveur SMTP <Tooltip
                                    title={"Aucun email ne sera envoyé si l'adresse est vide ou incorrect"}><InfoIcon
                                    fontSize={"small"}/></Tooltip></label>
                        </td>
                        <td>
                            <TextField id="outlined-basic" variant="outlined"
                                       className={classes.textField}
                                       value={mailUrl}
                                       onChange={handleChangeMailUrl}/>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <label className="label-input">Utilisateur du serveur STMP</label>
                        </td>
                        <td>
                            <TextField id="outlined-basic" variant="outlined"
                                       className={classes.textField}
                                       value={mailLogin}
                                       onChange={handleChangeMailLogin}/>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <label className="label-input">Mot de passe du serveur STMP</label>
                        </td>
                        <td>
                            <TextField id="outlined-basic" variant="outlined"
                                       type="password"
                                       className={classes.textField}
                                       value={mailPassword}
                                       onChange={handleChangeMailPassword}/>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <label className="label-input">Adresse email de l'émetteur<Tooltip
                                title={"L'adresse elibrairie@hachette-livre.fr sera utilisée par défaut si ce champs est vide."}><InfoIcon
                                fontSize={"small"}/></Tooltip></label>
                        </td>
                        <td>
                            <TextField id="outlined-basic" variant="outlined"
                                       className={classes.textField}
                                       value={mailFrom}
                                       onChange={handleChangeMailFrom}/>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <label className="label-input">Adresse email du destinataire des alertes sur les fichiers en erreurs lors des import elib ou cyclade<Tooltip
                                title={"L'adresse infoedit@hachette-livre.fr sera utilisée par défaut si ce champs est vide."}><InfoIcon
                                fontSize={"small"}/></Tooltip></label>
                        </td>
                        <td>
                            <TextField id="outlined-basic" variant="outlined"
                                       className={classes.textField}
                                       value={mailErrorFileEmail}
                                       onChange={handleChangeMailErrorFileEmail}/>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            <Grid container direction="row" justify="flex-end">
                                <Button variant="contained" color="primary" className="button-save"
                                        onClick={saveSendEmailActive}>
                                    Enregistrer
                                </Button>
                            </Grid>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div className="param-content-system">
                <h4>Gestion des miniatures</h4>
                <div className="param-block-system">
                    <Grid container direction="row" justify="space-between" alignItems="center">
                        <label>Dossier racine</label>
                        <TextField error={thumbnailBaseDirectoryError} id="outlined-basic" variant="outlined"
                                   className={classes.textField2}
                                   value={thumbnailBaseDirectory}
                                   onChange={handleChangeThumbnailPath}/>

                        <ButtonGroup variant="contained">
                            {
                                <Button style={{width: 245}} variant="contained" color="primary"
                                        className="button-save"
                                        onClick={generateThumbnail}
                                        disabled={params?.generationOfThumbnailInProgress}>
                                    {params?.generationOfThumbnailInProgress ? "Généreration en cours ..." : "Regénérer les miniatures"}
                                </Button>
                            }
                            <Button color="secondary" onClick={stopThumbnailGeneration}
                                    disabled={!params?.generationOfThumbnailInProgress}> <HighlightOff
                                fontSize="small"/>
                            </Button>
                        </ButtonGroup>


                        <Button variant="contained" color="primary" className="button-save"
                                onClick={saveBasePath}>
                            Enregistrer
                        </Button>


                    </Grid>
                </div>
            </div>
        </div>
    )
}
