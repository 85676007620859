import * as React from "react";
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {State} from "../reducer";
import {getContact} from "../action/contactAction";
import {ContactTable} from "./ContactTable";
import {CollectionNode, Contact, ContactDisplay, ContactMarqueCollectionAdd, Node} from "../types";
import {addContactAction, deleteContactAction} from "../epic/contactEpic";
import {notifyWarning} from "../action/notification.actions";
import {axiosInstance} from "../store";

export const Members: React.FC = (props) => {

    const dispatch = useDispatch();
    const auth = useSelector(({auth}: State) => auth);
    const contacts = useSelector(({contacts}: State) => contacts.contacts);
    console.log(contacts)

    useEffect(() => {
        dispatch(getContact());
    }, [dispatch]);

    const contactList = () => {
        let res: ContactDisplay[] = [];
        contacts.forEach(row => {
            row.contact.forEach(contact => {
                res.push({
                    id: contact.id,
                    firstname: contact.firstname,
                    lastname: contact.lastname,
                    marque: {
                        label: row.codeMarqueLabel,
                        value: row.codeMarque
                    } as Node,
                    collection: {
                        label: row.codeCollectionLabel,
                        value: row.codeCollection
                    } as CollectionNode,
                    email: contact.email,
                    entitledPostal: contact.entitledPostal,
                    departement: contact.departement,
                });
            })
        });
        return res;
    };


    const addContactRow = (newContact: Contact, marqueCollection: Node, collection?: CollectionNode) => {
        if (!marqueCollection || !newContact || newContact.email === "") {
            dispatch(notifyWarning("Veuillez choisir un email et une marque collection."))
        } else {
            const contactMarqueCollection: ContactMarqueCollectionAdd = {
                id: 0,
                contact: newContact,
                codeMarque: marqueCollection.value,
                codeMarqueLabel: marqueCollection.label
            }

            let coll = null;
            if (collection && collection.value !== "") {
                const contactSplit = collection.value.split(".");
                if (contactSplit.length > 1) {
                    coll = contactSplit[1];
                }
            }
            if (coll) {
                contactMarqueCollection.codeCollection = coll;
                if (collection)
                    contactMarqueCollection.codeCollectionLabel = collection.label;
            }

            addContactAction(axiosInstance, contactMarqueCollection, auth.token);
        }
    };

    const deleteContactRow = (oldContact: ContactDisplay) => {
        deleteContactAction(axiosInstance, oldContact, auth.token);
    };

    return (
        <div className="members-content">
            <h3 className="h3-title">Destinataires des alertes</h3>
            <ContactTable contacts={contactList()} addContactRow={addContactRow} deleteContactRow={deleteContactRow}/>
        </div>
    )
}
