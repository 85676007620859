export const formatDate = (date: Date) => {
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dt = date.getDate();

    let day: string = dt.toString();
    let monthString: string = month.toString();

    if (dt < 10) {
        day = '0' + dt;
    }
    if (month < 10) {
        monthString = '0' + month;
    }
    return day + "/" + monthString + "/" + year;
};