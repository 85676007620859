import {applyMiddleware, createStore, Dispatch, Middleware, MiddlewareAPI} from "redux";
import {keycloakMiddleware} from "./keycloak";
import reducer, {State} from "./reducer";
import {Action, isActionWithPayload} from "./action/utils";
import {createEpicMiddleware} from 'redux-observable';
import {rootEpic} from "./epic";
import {createBrowserHistory, History} from "history";
import axios, {AxiosInstance} from "axios";
import {baseApiUrl} from "./const";

export type EpicDependencies = {
    axios: AxiosInstance,
    history: History
}

const loggerMiddleware: Middleware = (_: MiddlewareAPI) => (next: Dispatch) => (action: Action) => {
    if (isActionWithPayload(action)) {
        console.log('action', action.type, action.payload);
    } else {
        console.log('action', action.type);
    }
    return next(action);
};

export const history = createBrowserHistory();

export const axiosInstance = axios.create(
    {
        timeout: 30000,
        baseURL: baseApiUrl,
        headers: {
            'Content-Type': 'application/json;charset=utf-8'
        }
    }
)

const epicMiddleware = createEpicMiddleware<any, any, State, EpicDependencies>({
    dependencies: {
        axios: axiosInstance,
        history
    }
});

export default createStore(
    reducer,
    applyMiddleware(keycloakMiddleware, loggerMiddleware, epicMiddleware)
);

epicMiddleware.run(rootEpic);

