import * as React from "react";
import {useEffect, useState} from "react";
import 'date-fns';
import {Button, FormControlLabel} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import {
    deleteElibAction,
    saveCronTimeAction,
    saveMonthDateImportCycladeAction,
    saveElibAction,
    saveImportCycladeAction,
    saveDeleteOldOuvrageAction,
    saveCleanSftpAction,
    saveNbrDaysBeforAfterMveAction
} from "../epic/ParametrageEpic";

import {useDispatch, useSelector} from "react-redux";
import {State} from "../reducer";
import Grid from "@material-ui/core/Grid";
import {getParams} from "../action/parametrageAction";
import {getElibs} from "../action/elibAction"
import Divider from "@material-ui/core/Divider";
import Checkbox from "@material-ui/core/Checkbox";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from '@material-ui/icons/Delete';
import {Elib} from "../types";
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from '@material-ui/icons/Info';
import {RunParameter} from "./RunParameter";
import {notifyError, notifySuccess} from "../action/notification.actions";
import {axiosInstance} from "../store";

export const ApplicationParameter: React.FC = () => {
    const parametrageStatus = useSelector(({parametrage}: State) => parametrage.status);
    const auth = useSelector(({auth}: State) => auth);
    const dispatch = useDispatch();

    const elibs = useSelector(({elib}: State) => elib.elib);


    useEffect(() => {
            dispatch(getParams());
            dispatch(getElibs());
        }, [dispatch]
    )

    const params = useSelector(({parametrage}: State) => parametrage.params);


    const [nbrDayBeforeMve, setNbrDayBeforeMve] = useState(params?.nbrJourMinAff ?? null);
    const [nbrDayAfterMve, setNbrDayAfterMve] = useState(params?.nbrJourMaxAff ?? null);
    const [nbrMonthStartMev, setNbrMonthStartMev] = useState(params?.nbrMoisImportCyclade ?? null);


    const handleChangeImportElib = (event: React.ChangeEvent<HTMLInputElement>) => {
        setImportElib(event.target.value);
    };

    const date = new Date();
    const defaultTime = date.getHours() + ":" + date.getMinutes()
    const [importElib, setImportElib] = useState(params?.cronImportElib ?? defaultTime);
    const [importCyclade, setImportCyclade] = useState(params?.cronImportCyclade ?? defaultTime);
    const [deleteOldOuvrage, setDeleteOldOuvrage] = useState(params?.cronDeleteOld ?? defaultTime);
    const [cleanSftp, setCleanSftp] = useState(params?.cronDeleteProcessedFiles ?? defaultTime);



    useEffect(() => {
        if (params) {
            setImportCyclade(params.cronImportCyclade)
            setImportElib(params.cronImportElib)
            setDeleteOldOuvrage(params.cronDeleteOld)
            setCleanSftp(params.cronDeleteProcessedFiles)
            setNbrDayBeforeMve(params.nbrJourMinAff)
            setNbrDayAfterMve(params.nbrJourMaxAff)
            setNbrMonthStartMev(params.nbrMoisImportCyclade)
        }
    }, [parametrageStatus, params])

    const [importCycladeError, setImportCycladeError] = useState(false);
    const [importElibError, setImportElibError] = useState(false);
    const [deleteOldOuvrageError, setDeleteOldOuvrageError] = useState(false);
    const [cleanSftpError, setCleanSftpError] = useState(false);

    const handleChangeImportCyclade = (event: React.ChangeEvent<HTMLInputElement>) => {
        setImportCyclade(event.target.value);
    };

    const handleChangeDeleteOldOuvrage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDeleteOldOuvrage(event.target.value);
    };

    const handleChangeCleanSftp = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCleanSftp(event.target.value);
    };

    const saveImportCyclade = () => {
        if (!importCyclade || !importCyclade.match("^([0-1]?\\d|2[0-3])(?::([0-5]?\\d))?(?::([0-5]?\\d))?$")) {
            dispatch(notifyError("Le temps saisit doit être au format HH:MM.", true))
            setImportCycladeError(true)
        } else {
            saveImportCycladeAction(axiosInstance, importCyclade, auth.token)
                .then(() => {
                    dispatch(notifySuccess("La configuration a été mis à jour."))
                    setImportCycladeError(false);
                })
                .catch(() => {
                    dispatch(notifyError("Une erreur interne est survenue.", true))
                    setImportCycladeError(true)
                });
        }
    };

    const saveImportElib = () => {
        if (!importElib || !importElib.match("^([0-1]?\\d|2[0-3])(?::([0-5]?\\d))?(?::([0-5]?\\d))?$")) {
            dispatch(notifyError("Le temps saisit doit être au format HH:MM.", true))
            setImportElibError(true)
        } else {
            saveCronTimeAction(axiosInstance, importElib, auth.token)
                .then(() => {
                    dispatch(notifySuccess("La configuration a été mis à jour."))
                    setImportElibError(false);
                })
                .catch(() => {
                    dispatch(notifyError("Une erreur interne est survenue.", true))
                    setImportElibError(true)
                });
        }
    };

    const saveDeleteOldOuvrage = () => {
        if (!deleteOldOuvrage || !deleteOldOuvrage.match("^([0-1]?\\d|2[0-3])(?::([0-5]?\\d))?(?::([0-5]?\\d))?$")) {
            dispatch(notifyError("Le temps saisit doit être au format HH:MM.", true))
            setDeleteOldOuvrageError(true)
        } else {
            saveDeleteOldOuvrageAction(axiosInstance, deleteOldOuvrage, auth.token)
                .then(() => {
                    dispatch(notifySuccess("La configuration a été mis à jour."))
                    setDeleteOldOuvrageError(false);
                })
                .catch(() => {
                    dispatch(notifyError("Une erreur interne est survenue.", true))
                    setDeleteOldOuvrageError(true)
                });
        }
    };

    const saveCleanSftp = () => {
        if (!cleanSftp || !cleanSftp.match("^([0-1]?\\d|2[0-3])(?::([0-5]?\\d))?(?::([0-5]?\\d))?$")) {
            dispatch(notifyError("Le temps saisit doit être au format HH:MM.", true))
            setCleanSftpError(true)
        } else {
            saveCleanSftpAction(axiosInstance, cleanSftp, auth.token)
                .then(() => {
                    dispatch(notifySuccess("La configuration a été mis à jour."))
                    setCleanSftpError(false);
                })
                .catch(() => {
                    dispatch(notifyError("Une erreur interne est survenue.", true))
                    setCleanSftpError(true)
                });
        }
    };

    const [nbrMonthStartMevError, setNbrMonthStartMevError] = useState(false);

    const handleChangeNbrMonthMev = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNbrMonthStartMev(parseInt(event.target.value));
    };

    const [nbrDayBeforeMveError, setNbrDayBeforeMveError] = useState(false);
    const [nbrDayAfterMveError, setNbrDayAfterMveError] = useState(false);

    const handleChangeNbrBefore = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNbrDayBeforeMve(parseInt(event.target.value));
    };
    const handleChangeNbrAfter = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNbrDayAfterMve(parseInt(event.target.value));
    };

    const setError = () => {
        dispatch(notifyError("Un champs est manquant. Veuillez remplir tous les champs obligatoires.", true))
    }

    const saveNbrDaysBeforAfterMve = () => {
        if (!nbrDayBeforeMve || nbrDayBeforeMve === 0) {
            setError()
            setNbrDayBeforeMveError(true);
            return;
        }
        if (!nbrDayAfterMve || nbrDayAfterMve === 0) {
            setError()
            setNbrDayAfterMveError(true);
            return;
        }

        saveNbrDaysBeforAfterMveAction(axiosInstance, nbrDayBeforeMve!!, nbrDayAfterMve!!, auth.token)
            .then(() => {
                dispatch(notifySuccess("La configuration a été mis à jour."))
            })
            .catch(() => {
                dispatch(notifyError("Une erreur interne est survenue.", true))
            });
        setNbrDayAfterMveError(false);
        setNbrDayBeforeMveError(false);

    };

    const saveMonthDateImportCyclade = () => {
        if (nbrMonthStartMev === null) {
            dispatch(notifyError("Le nombre de mois n'est pas valide.", true))
            setNbrMonthStartMevError(true);
        } else {
            saveMonthDateImportCycladeAction(axiosInstance, nbrMonthStartMev, auth.token)
                .then(() => {
                    dispatch(notifySuccess("La configuration a été mis à jour."))
                })
                .catch(() => {
                    dispatch(notifyError("Une erreur interne est survenue.", true))
                });
            setNbrMonthStartMevError(false);
        }
    };

    const [elibNameError, setElibNameError] = useState(false);
    const [templateElibError, setTemplateElibError] = useState(false);

    const handleChangeElibName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setElibName(event.target.value);
    };
    const handleChangeTemplateElib = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTemplateElib(event.target.value);
    };
    const handleChangeSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
        setActive(event.target.checked)
    };

    const [elibName, setElibName] = useState("");
    const [elibIdToUpdate, setElibIdToUpdate] = useState(0);
    const [templateElib, setTemplateElib] = useState("");
    const [active, setActive] = useState(false);


    const saveElib = () => {
        if (elibName === "" || elibName === null || elibName === undefined || templateElib === "" || templateElib === null || templateElib === undefined) {
            dispatch(notifyError("Un champs est manquant. Veuillez remplir tous les champs obligatoires.", true))
            if (elibName === "" || elibName === null || elibName === undefined) {
                setElibNameError(true);
            }
            if (templateElib === "" || templateElib === null || templateElib === undefined) {
                setTemplateElibError(true);
            }
        } else {
            let elib: Elib;
            elib = {
                id: elibIdToUpdate,
                template: templateElib,
                label: elibName,
                active: active
            };
            saveElibAction(axiosInstance, elib, auth.token)
                .then((response: any) => {
                    dispatch(notifySuccess("L'elibrairie a été mis à jour."))
                    elib.id = response.data;
                    setElibIdToUpdate(0);
                    setElibName("");
                    setTemplateElib("");
                    setActive(false);
                    dispatch(getElibs());
                })
                .catch(() => {
                    dispatch(notifyError("Une erreur interne est survenue.", true))
                });
            setTemplateElibError(false);
            setElibNameError(false);
        }
    };

    const editElib = (elib: Elib) => {
        setElibName(elib.label);
        setTemplateElib(elib.template);
        setActive(elib.active);
        setElibIdToUpdate(elib.id);
    };

    const deleteElib = (elib: Elib) => {
        deleteElibAction(axiosInstance, elib, auth.token)
            .then((response: any) => {
                dispatch(getElibs());
            })
            .catch(() => {
                dispatch(notifyError("Une erreur interne est survenue.", true))
            });
    };

    return (
        <div>
            <h3 className="h3-title">Paramétrage de l'Application</h3>
            <div style={{padding: '0 10rem 0 10rem'}}>
                <h4> Général</h4>
                <Grid container direction="row">

                    <table className="table-param-1">
                        <tbody>
                        <tr>
                            <td colSpan={2}>
                                <label>Importer les ouvrages dont la date de mise en vente date de moins de </label>
                            </td>
                            <td>
                                <div className="param-date">
                                <TextField error={nbrMonthStartMevError} id="outlined-basic"
                                           variant="outlined"
                                           type="number"
                                           value={nbrMonthStartMev} onChange={handleChangeNbrMonthMev}/>  
                                </div>
                            </td>
                            <td>
                                <label> mois</label>
                            </td>
                            <Grid container direction="row" justify="flex-end">
                                <Button variant="contained" color="primary" className="button-save"
                                        style={{marginTop: 27, marginLeft: 15}}
                                        onClick={saveMonthDateImportCyclade}>
                                    Enregistrer
                                </Button>
                            </Grid>
                        </tr>
                        <tr>
                            <td>
                                <label>Nombre de jours avant la date de mise en vente</label>
                            </td>
                            <td>
                                <TextField error={nbrDayBeforeMveError} id="outlined-basic"
                                           variant="outlined"
                                           type="number"
                                           value={nbrDayBeforeMve} onChange={handleChangeNbrBefore}/>
                            </td>
                            <td>
                                <label>Nombre de jours après la date de mise en vente</label>
                            </td>
                            <td>
                                <TextField error={nbrDayAfterMveError} id="outlined-basic"
                                           variant="outlined"
                                           type="number"
                                           value={nbrDayAfterMve} onChange={handleChangeNbrAfter}/>
                            </td>
                            <Grid container direction="row" justify="flex-end">
                                <Button variant="contained" color="primary" className="button-save"
                                        style={{marginTop: 19, marginLeft: 15}}
                                        onClick={saveNbrDaysBeforAfterMve}>
                                    Enregistrer
                                </Button>
                            </Grid>
                        </tr>
                        <RunParameter defaultRunNumbersToHide={params?.runNumbersToHide ?? []}/>
                        </tbody>
                    </table>
                </Grid>

            </div>

            <div className="divider">
                <Divider variant="middle"/>
            </div>

            <h4 className="label-h4"> Ajouter une elibrairie </h4>
            <div className="param-content-elib">

                <table className="table-param-3">
                    <tbody>
                    <tr>
                        <td className="td-complement">
                            <label className="label-input">Nom elibrairie</label>
                        </td>
                        <td>
                            <TextField error={elibNameError} id="outlined-basic" label="Value" variant="outlined"
                                       value={elibName} onChange={handleChangeElibName} style={{width: "50%"}}/>
                        </td>
                    </tr>
                    <tr>
                        <td className="td-complement">
                            <label className="label-input">Template <Tooltip
                                title={"%EAN% sera remplacé par l'ean de l'ouvrage, %ISBN% par l'isbn 10"}><InfoIcon
                                fontSize={"small"}/></Tooltip></label>
                        </td>
                        <td>
                            <TextField error={templateElibError} id="outlined-basic" label="Value"
                                       variant="outlined" style={{width: "90%"}}
                                       value={templateElib} onChange={handleChangeTemplateElib}/>
                        </td>
                    </tr>
                    <tr>
                        <td className="td-complement">
                            <label className="label-input">Activer / Désactiver</label>
                        </td>
                        <td>
                            <FormControlLabel
                                control={<Checkbox checked={active}
                                                   onChange={(event) => handleChangeSelection(event)}/>}
                                label=""
                            />
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            <Grid container direction="row" justify="center">
                                <Button variant="contained" color="primary" className="button-save"
                                        onClick={saveElib}>
                                    Enregistrer
                                </Button>
                            </Grid>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <div className="div-table-elib">
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Nom Elibrairie</TableCell>
                                    <TableCell>Template</TableCell>
                                    <TableCell align="center">Statut</TableCell>
                                    <TableCell align="center"/>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {elibs && elibs.map((elib) => (
                                    <TableRow key={elib.id}>
                                        <TableCell>{elib.label}</TableCell>
                                        <TableCell>{elib.template}</TableCell>
                                        <TableCell align="center">
                                            <FormControlLabel
                                                control={<Checkbox checked={elib.active} disabled={true}/>}
                                                label=""
                                            />
                                        </TableCell>
                                        <TableCell align="center">
                                            <EditIcon className="icon" onClick={() => editElib(elib)}/>
                                            <DeleteIcon className="icon" onClick={() => deleteElib(elib)}/>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>

            <div className="divider">
                <Divider variant="middle"/>
            </div>
            <div className="param-content-system">
                <h4 style={{marginBottom: 25}}> Import Elib</h4>
                <label className="cron-label">Heure de lancement automatique </label>
                <TextField error={importElibError} id="outlined-basic"
                           variant="outlined"
                           type="time" value={importElib}
                           onChange={handleChangeImportElib}/>

                <Grid container direction="row" justify="flex-end">
                    <Button variant="contained" color="primary" className="button-save" onClick={saveImportElib}>
                        Enregistrer
                    </Button>
                </Grid>
            </div>
            <div className="divider">
                <Divider variant="middle"/>
            </div>
            <div className="param-content-system">
                <h4 style={{marginBottom: 25}}> Import Cyclade</h4>
                <label className="cron-label">Heure de lancement automatique </label>
                <TextField error={importCycladeError} id="outlined-basic"
                           variant="outlined"
                           type="time" value={importCyclade}
                           onChange={handleChangeImportCyclade}/>
                <Grid container direction="row" justify="flex-end">
                    <Button variant="contained" color="primary" className="button-save" onClick={saveImportCyclade}>
                        Enregistrer
                    </Button>
                </Grid>
            </div>
            <div className="divider">
                <Divider variant="middle"/>
            </div>
            <div className="param-content-system">
                <h4 style={{marginBottom: 25}}> Suppression des anciens ouvrages</h4>
                <label className="cron-label">Heure de lancement automatique </label>
                <TextField error={deleteOldOuvrageError} id="outlined-basic"
                           variant="outlined"
                           type="time" value={deleteOldOuvrage}
                           onChange={handleChangeDeleteOldOuvrage}/>
                <Grid container direction="row" justify="flex-end">
                    <Button variant="contained" color="primary" className="button-save" onClick={saveDeleteOldOuvrage}>
                        Enregistrer
                    </Button>
                </Grid>
            </div>
            <div className="divider">
                <Divider variant="middle"/>
            </div>
            <div className="param-content-system">
                <h4 style={{marginBottom: 25}}> Nettoyage du SFTP</h4>
                <label className="cron-label">Heure de lancement automatique </label>
                <TextField error={cleanSftpError} id="outlined-basic"
                           variant="outlined"
                           type="time" value={cleanSftp}
                           onChange={handleChangeCleanSftp}/>
                <Grid container direction="row" justify="flex-end">
                    <Button variant="contained" color="primary" className="button-save" onClick={saveCleanSftp}>
                        Enregistrer
                    </Button>
                </Grid>
            </div>
        </div>
    )
}
