import * as React from "react";
import { useState} from "react";
import {CardContent, createStyles, FormControlLabel} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Checkbox from '@material-ui/core/Checkbox';
import Modal from "react-bootstrap/Modal";
import {Elib, Image, Ouvrage} from "../types";
import {deadSmile, greenSmile, pokerFace, redSmile} from "../const";
import Tooltip from "@material-ui/core/Tooltip";
import {formatDate} from "../utils/datFormat";
import {borderClass, getTitleWithRevInfo, isComplementFeuilletage, showImage} from "../utils/ouvrageUtils";
import { useSelector } from "react-redux";
import {State} from "../reducer";



const useStyles = makeStyles(() =>
    createStyles({
        checkBox: {
            padding: '0 .5rem 0 1rem',
            fontSize: '12px'
        }
    }),
);


export const OuvrageDetails = React.forwardRef((props, ref) => {

    const elibs: Elib[] = useSelector(({elib}: State) => elib.elib)
    const initOuvrage: Ouvrage = {
        images: [],
        identart: "",
        ean: "",
        isbn10: "",
        mevDate: "",
        run: 1,
        price: 1,
        codePyramide: "",
        idCodePyramide: "",
        division: "",
        lastModifCyclade: "",
        lastVerfiCyclade: "",
        fluxLibrairie: false,
        nuart: 1,
        editorial: "",
        collection: "",
        argumentaire: "",
        status: "",
        statusCyclade: "",
        check: "",
        refresh: "",
        title: "",
        msgStatusCyclade: "",
        statusElib: "",
        msgStatusElib: "",
        complement: [],
        elibOuvrages: [],
        remisEnVente: false,
    };

    const [showModal, setShowModal] = useState(false);
    const [ouvrage, setOuvrage] = useState(initOuvrage)

      React.useImperativeHandle(ref, () => ({
        updateShowDetails(value: boolean) {
            setShowModal(value)
          },
          updateSelectedOuvrage(ouvrage: Ouvrage) {
            setOuvrage(ouvrage)
          }
      }));

    const classes = useStyles();
    


    const getSmile = (value: string) => {
        switch (value) {
            case 'Non valide' :
                return redSmile;
            case 'Valide' :
                return greenSmile;
            case 'Non vérifié':
                return deadSmile;
            case 'Initialisé':
                return deadSmile;
            case 'Inconnu':
                return pokerFace;
        }
    };

    const buildElibUrl = (template: string, ouvrage: Ouvrage, elibId: number): string => {

        let url = template.replace("%EAN%", ouvrage.ean).replace("%ISBN%", ouvrage.isbn10)
        ouvrage.elibOuvrages.forEach( (value) =>{
            if(value.elib.id === elibId) {
                if(value.url !== null && value.url.length >0) {
                    url = value.url
                }                
            }
        })
        return url
    }

    const closeModal = () => setShowModal(false);

    function formatOctetSize(size: number): string {
        const def = ['octets', 'Ko', 'Mo', 'Go']

        for (let i = 1; i < def.length; i++) {
            if (size < Math.pow(1024, i)) return (size / Math.pow(1024, i - 1)).toFixed(2) + ' ' + def[i - 1];
        }

        return size.toFixed(2) + 'octets'
    }

    const cover: Image | undefined = ouvrage?.images?.filter(x => x.type === 'GR1')[0];
    const backCover: Image | undefined = ouvrage?.images?.filter(x => x.type === 'RE2')[0];
    const getElibOuvrage = (elibId: number) => {
        let smile = getSmile('Non vérifié')
        ouvrage.elibOuvrages.forEach( (value) =>{

            if(value.elib.id === elibId) {
                smile = getSmile(value.status)
            }
        }
        )
        return smile;
    }
    const getFeuilleter = (elibId: number) => {
        let feuilleter = false
        ouvrage.elibOuvrages.forEach( (value) =>{

            if(value.elib.id === elibId) {
                feuilleter = value.feuilleter
            }
        }
        )
        return feuilleter
    }

    return (
        <div>
            <Modal show={showModal} onHide={closeModal} size="xl">
                <Modal.Header closeButton>
                    <span className="label-title">{getTitleWithRevInfo(ouvrage)}</span>
                </Modal.Header>
                <Modal.Body>
                    <Card>
                        <CardContent>
                            <div className="content-details">
                                <div className="image-details">
                                    <img id="cover-image" alt="" src={showImage('medium', cover?.url)}
                                         className={(cover?.url === undefined || cover?.url === "") ? "no-image" : "image " + cover ? borderClass(cover?.isVisuelProvisoire) : ''}/>
                                    {
                                        ouvrage.complement || backCover ?
                                            <table style={{marginTop: '2rem'}}>
                                                <tbody>
                                                {
                                                    backCover &&
                                                    <tr key={'back-cover'} className="tr-border">
                                                        <td className="cell-complement-details">
                                                            <a href={backCover.url} target={"_blank"} rel="noopener noreferrer">
                                                                <img src={showImage('small', backCover.url, 'jpeg')}
                                                                     alt=""
                                                                     style={{
                                                                         height: 100,
                                                                         padding: 5,
                                                                         marginLeft: "auto",
                                                                         marginRight: "auto",
                                                                         display: "block",
                                                                         cursor: "pointer",
                                                                         marginBottom: 10
                                                                     }}
                                                                     className={borderClass(backCover.isVisuelProvisoire)}
                                                                />
                                                            </a>
                                                        </td>
                                                        <td className="td-padding">
                                                            <table>
                                                                <tbody>
                                                                <tr>
                                                                    <td className="cell-complement-details"> 4ème de
                                                                        couverture
                                                                    </td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                }
                                                {
                                                    ouvrage.complement.sort((a,b) => a.name > b.name ? 1 : -1).map((complement, it) => (
                                                        <tr key={it} className="tr-border">
                                                            <td className="cell-complement-details">
                                                                {
                                                                    complement.format !== null && !isComplementFeuilletage(complement.url, complement.format) ?
                                                                        <a href={complement.url} target={"_blank"} rel="noopener noreferrer">
                                                                            <img
                                                                                src={showImage('small', complement.url, complement.format)}
                                                                                alt=""
                                                                                style={{
                                                                                    height: 100,
                                                                                    padding: 5,
                                                                                    marginLeft: "auto",
                                                                                    marginRight: "auto",
                                                                                    display: "block",
                                                                                    cursor: "pointer"
                                                                                }}
                                                                                className={complement.publicationOnline && complement.extractOnline ? " green-border" : " orange-border"}
                                                                            />
                                                                        </a> : <img
                                                                            src={showImage('small', complement.url, complement.format)}
                                                                            alt=""
                                                                            style={{
                                                                                height: 100,
                                                                                padding: 5,
                                                                                marginLeft: "auto",
                                                                                marginRight: "auto",
                                                                                display: "block"
                                                                            }}
                                                                            className={complement.publicationOnline && complement.extractOnline ? " green-border" : " orange-border"}
                                                                        />}
                                                            </td>
                                                            <td className="td-padding">
                                                                <table>
                                                                    <tbody>
                                                                    <tr>
                                                                        <td colSpan={2} className="cell-complement-details-title">
                                                                            {complement.name}
                                                                            </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="cell-complement-details"> Dernière
                                                                            modifcation
                                                                        </td>
                                                                        <td className="cell-complement-details">{complement.lastModifBdd === null || complement.lastModifBdd === "" ? "" : new Date(complement.lastModifBdd).toLocaleString('fr-FR')}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="cell-complement-details">
                                                                            Publication en ligne
                                                                        </td>
                                                                        <td align="center"><FormControlLabel
                                                                            control={<Checkbox readOnly
                                                                                               disabled={true}
                                                                                               checked={complement.publicationOnline}
                                                                                               color="primary"
                                                                                               className={classes.checkBox}/>}
                                                                            label=""
                                                                        /></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="cell-complement-details">Extraction
                                                                            en
                                                                            ligne
                                                                        </td>
                                                                        <td align="center"><FormControlLabel
                                                                            control={<Checkbox readOnly
                                                                                               disabled={true}
                                                                                               checked={complement.extractOnline}
                                                                                               color="primary"
                                                                                               className={classes.checkBox}/>}
                                                                            label=""
                                                                        /></td>
                                                                    </tr>
                                                                    {(complement && complement.dimension && complement.dimension !== "") &&
                                                                    <tr>
                                                                        <td className="cell-complement-details"> Dimension
                                                                        </td>
                                                                        <td className="cell-complement-details">{complement.dimension} px</td>
                                                                    </tr>
                                                                    }
                                                                    {(complement && complement.size && complement.size !== "" && complement.size !== "0") &&
                                                                    <tr>
                                                                        <td className="cell-complement-details"> Taille
                                                                        </td>
                                                                        <td className="cell-complement-details">{formatOctetSize(parseFloat(complement.size))}</td>
                                                                    </tr>
                                                                    }
                                                                    <tr>
                                                                        <td className="cell-complement-details"> Format
                                                                        </td>
                                                                        <td className="cell-complement-details">
                                                                            {isComplementFeuilletage(complement.url, complement.format) ? "Feuilletage" : complement.format}
                                                                            </td>
                                                                    </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                                </tbody>
                                            </table> : ""
                                    }
                                </div>
                                <div className="details">
                                    <Card>
                                        <CardContent>
                                            <table>
                                                <tbody>
                                                <tr>
                                                    <td align="center" colSpan={2}>
                                                        <b>INFORMATIONS</b>
                                                    </td>
                                                </tr>
                                                <tr className="presentation">
                                                {ouvrage.argumentaire && ouvrage.argumentaire.length > 0 ?
                                                    <td colSpan={2}>
                                                        {ouvrage.argumentaire}
                                                    </td> :
                                                    <td colSpan={2} className="emptyField">
                                                        Pas de Texte de présentation
                                                    </td>
                                                }
                                                    
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <label className="ouvrage-data"><b>Sous-titre</b></label>
                                                    </td>
                                                    <td>
                                                        {ouvrage.subtitle ?? ""}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <label className="ouvrage-data"><b>EAN</b></label>
                                                    </td>
                                                    <td>
                                                        <a target={"_blank"} rel="noopener noreferrer"
                                                           href={`https://cyclade.hachette-livre.fr/cyclade/article/enrichment/${ouvrage.identart}`}>{ouvrage.ean}</a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td><label className="ouvrage-data"><b>Prix</b></label></td>
                                                    <td> {ouvrage.price.toLocaleString('fr-FR', {
                                                        style: "currency",
                                                        currency: "EUR",
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2
                                                    })}</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <label className="ouvrage-data"><b>NUART</b> </label>
                                                    </td>
                                                    <td>{ouvrage.nuart}</td>
                                                </tr>
                                                <tr>
                                                    <td className="cell-titles" align="left">
                                                        <label  className="ouvrage-data"><b>Date de MEV</b></label>
                                                    </td>
                                                    <td>{formatDate(new Date(ouvrage.mevDate))}</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <label className="ouvrage-data"><b>RUN</b></label>
                                                    </td>
                                                    <td>{ouvrage.run}</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <label className="ouvrage-data"><b>Pyramide</b></label>
                                                    </td>
                                                    <td>{ouvrage.codePyramide}</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <label className="ouvrage-data"><b>Division</b></label>
                                                    </td>
                                                    <td>{ouvrage.division}</td>
                                                </tr>
                                                <tr>
                                                    <td className="cell-titles" align="left">
                                                        <label className="ouvrage-data"><b>Flux libraire</b> </label>
                                                    </td>
                                                    <td>
                                                        <FormControlLabel
                                                            control={<Checkbox readOnly
                                                                disabled={true}
                                                                checked={ouvrage.fluxLibrairie}
                                                                color="primary"
                                                                className={classes.checkBox}/>}
                                                            label=""
                                                        />
                                                    </td>
                                                </tr>
                                                        <tr>
                                                            <td className="cell-titles" align="left"><label
                                                                className="ouvrage-data"><b>Etat</b></label></td>
                                                            <td>{ouvrage.status}</td>
                                                        </tr>
                                                
                                                </tbody>
                                            </table>
                                        </CardContent>
                                    </Card>
                                    <div className="details-ouvrage">
                                        <div className="details-technique">
                                            <Card>
                                                <CardContent>
                                                    <table>
                                                        <tbody>
                                                        <tr>
                                                            <td align="center" colSpan={2}>
                                                                <b>INFORMATIONS TECHNIQUES</b>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <label className="ouvrage-data"><b>Vérification Cyclade </b></label>
                                                    
                                                                <Tooltip title={ouvrage.statusCyclade} enterDelay={500}  leaveDelay={200}>
                                                                    <img
                                                                        alt=""
                                                                        style={{height: 30, cursor: 'inherit'}}
                                                                        src={getSmile(ouvrage.statusCyclade)}
                                                                    />
                                                                </Tooltip>
                                                            </td>
                                                            <td>{ouvrage.msgStatusCyclade}</td>
                                                        </tr>
                                        
                                                        <tr>
                                                            <td className="cell-titles" align="left"><label
                                                                className="ouvrage-data"> <b>Dernière vérification</b></label>
                                                            </td>
                                                            <td>{ouvrage.lastVerfiCyclade === null || ouvrage.lastVerfiCyclade === "" ? "" : new Date(ouvrage.lastVerfiCyclade).toLocaleString('fr-FR')}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="cell-titles" align="left">
                                                                <label className="ouvrage-data"><b>Dernière modification</b></label>
                                                            </td>
                                                            <td>{new Date(ouvrage.lastModifCyclade).toLocaleString('fr-FR')}</td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </CardContent>
                                            </Card>
                                        </div>
                                    </div>
                                    <div className="details-ouvrage">
                                        <div className="details-elib-url">
                                            <Card>
                                                <CardContent>
                                                    <table>
                                                        <tbody>
                                                        <tr>
                                                            <td align="center" colSpan={2}>
                                                                <b>E-LIBRAIRIES</b>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                    <td>
                                                        <label className="ouvrage-data"><b>
                                                            Vérification elibrairie </b></label>
                                                    
                                                        <Tooltip title={ouvrage.statusCyclade} enterDelay={500}
                                                                 leaveDelay={200}>
                                                            <img
                                                                alt=""
                                                                style={{height: 30, cursor: 'inherit', alignSelf: 'center'}}
                                                                src={getSmile(ouvrage.statusElib)}
                                                            />
                                                        </Tooltip>
                                                    </td>
                                                    <td>
                                                        <div 
                                                             dangerouslySetInnerHTML={{__html: ouvrage.msgStatusElib}}/>
                                                    </td>
                                                </tr>
                                                        {
                                                            elibs ?
                                                                elibs.filter(x => x.active).map((e, it) => (
                                                                    <tr key={it} >
                                                                        <td className="cell-titles" align="left">
                                                                        <img
                                                                            alt=""
                                                                            style={{height: 20, display:'unset'}}
                                                                            src={getElibOuvrage(e.id)}
                                                                            />
                                                                            <label
                                                                            className="ouvrage-data"><b>{e.label}</b></label>
                                                                            {
                                                                                getFeuilleter(e.id) ? 
                                                                                <Tooltip title="Feuilletage disponible" enterDelay={500}  leaveDelay={200}>
                                                                                <img style={{height: 23, display:'unset', cursor: 'inherit'}} src="/images/icons8-livre-48.png" alt=""/> 
                                                                                </Tooltip>
                                                                                : null
                                                                            }
                                                                        </td>
                                                                        <td ><a
                                                                            href={buildElibUrl(e.template, ouvrage, e.id)}
                                                                            target="_blank"
                                                                            rel="noopener noreferrer">{buildElibUrl(e.template, ouvrage, e.id)}</a>
                                                                        </td>
                                                                    </tr>
                                                                )) : ""
                                                        }
                                                        </tbody>
                                                    </table>
                                                </CardContent>
                                            </Card>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                </Modal.Body>
            </Modal>
        </div>
    )
})
