import {createActionWithoutPayload, createActionWithPayload} from "./utils";
import {Elib, MarqueCollection} from "../types";


export const [getElibs, isGetElibsAction] = createActionWithoutPayload('GET_ELIBS');
export const [getElibsError, isGetElibsError] = createActionWithoutPayload('ELIBS_ERROR');
export const [getElibsSuccess, isGetElibsSuccess] = createActionWithPayload<Elib[]>('GET_ELIBS_SUCCESS');


export const [attachMarqueEditorialeToElib, isAttachMarqueEditorialeToElib] = createActionWithPayload<{ marqueId: string, collectionId?: string, elibId: number }>('ATTACH_MARQUE_EDITORIALE_TO_ELIB');
export const [dettachMarqueEditorialeToElib, isDettachMarqueEditorialeToElib] = createActionWithPayload<{ marqueId: string, collectionId?: string, elibId: number }>('DETTACH_MARQUE_EDITORIALE_TO_ELIB');


export const [getMarqueEditorialForElib, isGetMarqueEditorialForElib] = createActionWithPayload<number>('GET_MARQUE_EDITORIALE_FOR_ELIB');
export const [getMarqueEditorialForElibError, isGetMarqueEditorialForElibError] = createActionWithoutPayload('GET_MARQUE_EDITORIALE_FOR_ELIB_ERROR');
export const [getMarqueEditorialForElibSuccess, isGetMarqueEditorialForElibSuccess] = createActionWithPayload<MarqueCollection[]>('GET_MARQUE_EDITORIALE_FOR_ELIB_SUCCESS');
