import * as React from "react";

import 'date-fns';
import {useDispatch, useSelector} from "react-redux";
import {State} from "../../reducer";
import {ParamsMarqueCollection} from "../../types";
import {Grid} from "@material-ui/core";
import {NBComplementOperator} from "./NBComplementOperator";
import TextField from "@material-ui/core/TextField";
import {resetMarqueEditorialeParameter, updateMarqueEditorialeParameter} from "../../action/parametrageAction";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";


interface Props {
    marqueId: string,
    collectionId?: string
}

export const NBComplement: React.FC<Props> = ({marqueId, collectionId}) => {

    const defaultParameter = {
        marque: marqueId,
        collection: collectionId ?? ''
    } as ParamsMarqueCollection
    const dispatch = useDispatch();

    const parameter = useSelector(({parametrage}: State) => parametrage.currentParamsMarqueCollection ?? defaultParameter)
    return (<Grid container spacing={2}>
            <Grid item>
                Nombre de Compléments
            </Grid>
            <Grid item>
                <NBComplementOperator value={parameter.operatorComplement ?? ''} defaultParams={defaultParameter}/>
            </Grid>

            <Grid item>
                <TextField
                    type="number" InputProps={{
                    inputProps: {
                        max: 300, min: 0
                    }
                }}
                    style={{width: 50}}
                    value={parameter.nbrComplementExpected ?? ''} onChange={(event) => {
                    const newParam = {
                        ...defaultParameter,
                        nbrComplementExpected: parseInt(event.target.value),
                        operatorComplement: parameter.operatorComplement
                    }
                    dispatch(updateMarqueEditorialeParameter(newParam))
                }
                }
                />
            </Grid>
            {parameter.operatorComplement &&
            <Grid item>
                <IconButton color="primary" aria-label="Effacer" component="span" size={"small"}
                            onClick={() => {
                                dispatch(resetMarqueEditorialeParameter(
                                    {
                                        ...parameter,
                                        nbrComplementExpected: null,
                                        operatorComplement: null
                                    }
                                ))
                            }}>
                    <ClearIcon/>
                </IconButton>
            </Grid>
            }
        </Grid>
    )
};
