import * as React from "react";
import {useEffect, useState} from "react";
import MaterialTable, {Column} from "material-table";
import {CollectionNode, ContactDisplay, Node} from "../types";
import {Autocomplete} from "@material-ui/lab";
import {TextField} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {State} from "../reducer";
import {getMarqueEditorialWithCollections} from "../action/marqueEditorialAction";
import {getContact} from "../action/contactAction";
import {notifyError} from "../action/notification.actions";


interface Props {
    contacts: ContactDisplay[],
    deleteContactRow: Function,
    addContactRow: Function
}

export const ContactTable: React.FC<Props> = (props) => {
    const {contacts, deleteContactRow, addContactRow} = props;
    const marqueEditorialList = useSelector(({marqueEditorial}: State) => marqueEditorial.collections);

    const [state, setState] = useState(false);
    const [marqueEditorial, setMarqueEditorial] = useState<Node>();
    const columns: Column<ContactDisplay>[] = [
        {title: 'Nom', field: 'firstname'},
        {title: 'Prénom', field: 'lastname'},
        {title: 'E-mail', field: 'email'},
        {
            title: 'Marque', field: 'marque',
            render: (rowData: ContactDisplay) => (
                <div>
                    <label>
                        {rowData.marque.label}
                    </label>
                </div>
            ),
            editComponent: (props: any) => (
                <Autocomplete
                    options={marqueEditorialList}
                    getOptionLabel={(option) => option.label}
                    value={marqueEditorial}
                    onChange={(event: any, newValue: Node | null) => {
                        if (newValue != null) {
                            props.onChange(newValue);
                            setMarqueEditorial(newValue)
                        } else {
                            setMarqueEditorial(undefined);
                        }

                    }}
                    renderInput={(params) => <TextField {...params} label="Value" variant="outlined"/>}
                />
            )
        },
        {
            title: 'Collection', field: 'collection',
            render: (rowData: ContactDisplay) => (
                <div>
                    <label>
                        {rowData.collection?.label||''}
                    </label>
                </div>
            ),
            editComponent: (props: any) => (
                <Autocomplete
                    options={marqueEditorial?.children || []}
                    getOptionLabel={(option) => option.label}
                    onChange={(event: any, newValue: CollectionNode | null) => {
                        if (newValue != null) {
                            props.onChange(newValue);
                        }
                    }}
                    renderInput={(params) => <TextField {...params} label="Value" variant="outlined"/>}
                />
            )
        }
    ];
    const [data, setData] = useState(contacts);

    useEffect(() => {
        setData(contacts);
    }, [contacts]);

    const dispatch = useDispatch();

    const validateEmail = (email: string) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    useEffect(() => {
        dispatch(getMarqueEditorialWithCollections());
        dispatch(getContact());
    }, [dispatch]);

    const addContact = (newContact: ContactDisplay) => {
        if(newContact.marque === undefined) {
            dispatch(notifyError("La marque est obligatoire."))
            return
        }
        if(newContact.email === undefined || !validateEmail(newContact.email)) {
            dispatch(notifyError("L'email n'est pas valide."))
            return
        }
        addContactRow(newContact, newContact.marque, newContact.collection);
        contacts.push(newContact);
        setState(!state);
    };

    const deleteContact = (oldContact: ContactDisplay) => {
        deleteContactRow(oldContact);
        const index = data.indexOf(oldContact);
        contacts.splice(index, 1);
        setState(!state);
    };

    return (
        <div className="members-table">
            <MaterialTable
                title="Membres"
                columns={columns}
                data={data}
                editable={{
                    onRowAdd: (newData) =>
                        new Promise<void>((resolve) => {
                            setTimeout(() => {
                                resolve();
                                addContact(newData);
                            }, 600);
                        }),
                    onRowDelete: (oldData) =>
                        new Promise<void>((resolve) => {
                            setTimeout(() => {
                                resolve();
                                deleteContact(oldData);
                            }, 600);
                        }),
                }}
                options={{
                    actionsColumnIndex: -1
                }}
                localization={{
                    pagination: {
                        labelDisplayedRows: '{from}-{to} de {count}',
                        labelRowsSelect: 'Lignes'
                    },
                    toolbar: {
                        nRowsSelected: '{0} ligne(s) sélectionnée(s)',
                        searchPlaceholder: 'Recherche',
                        searchTooltip: 'Recherche'
                    },
                    header: {
                        actions: ''
                    },
                    body: {
                        editRow: {
                            deleteText: 'Êtes vous sûrs de supprimer ce contact ?',
                            cancelTooltip: 'Annuler',
                            saveTooltip: 'Valider'
                        },
                        deleteTooltip: 'Effacer',
                        emptyDataSourceMessage: 'Aucun enregistrement à afficher',
                        filterRow: {
                            filterTooltip: 'Filter'
                        }
                    }
                }}
            />
        </div>
    )
}
