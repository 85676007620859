import * as React from "react";
import {useEffect, useState} from "react";
import 'date-fns';

import {useDispatch, useSelector} from "react-redux";
import {State} from "../reducer";
import {getMarqueEditorial, getMarqueEditorialWithCollections} from "../action/marqueEditorialAction";
import {getAdGroup} from "../action/adGroupAction";
import {getElibs} from "../action/elibAction"
import {MarqueGroupSelection} from "./ApplicationParameter/MarqueGroupSelection";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import {MarqueCollectionSelection} from "./ApplicationParameter/MarqueCollectionSelection";
import {CollectionNode, Node} from "../types";
import {loadParameterForMarqueOrCollection} from "../action/parametrageAction"
import {NBComplement} from "./ApplicationParameter/NBComplement";
import {CircularProgress, Fab} from "@material-ui/core";
import {green} from "@material-ui/core/colors";
import {makeStyles} from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import SaveIcon from '@material-ui/icons/Save';
import Tooltip from "@material-ui/core/Tooltip";
import {NumberParameter} from "./ApplicationParameter/NumberParameter";
import {MarqueElibSelection} from "./ApplicationParameter/MarqueElibSelection";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonSuccess: {
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
    fabProgress: {
        color: green[500],
        position: 'absolute',
        top: -2,
        left: -2,
        zIndex: 1,
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}));

export const MarqueEditorialeParameter: React.FC = () => {
    const classes = useStyles();

    const dispatch = useDispatch();

    useEffect(() => {
            dispatch(getMarqueEditorial());
            dispatch(getMarqueEditorialWithCollections());
            dispatch(getAdGroup());
            dispatch(getElibs());
        }, [dispatch]
    )

    const marqueEditorialList = useSelector(({marqueEditorial}: State) => marqueEditorial.marqueEditorial);
    const collectionList = useSelector(({marqueEditorial}: State) => marqueEditorial.collections);
    const adGroupList = useSelector(({adGroup}: State) => adGroup.adGroup);
    const elibs = useSelector(({elib}: State) => elib.elib);


    let [selectedMarqueCollection, setSelectedMarque] = useState<Node>();
    let [selectedCollection, setSelectedColl] = useState<CollectionNode>();
    const status = useSelector(({parametrage}: State) => parametrage.status);


    useEffect(() => {
        if (selectedMarqueCollection)
            dispatch(loadParameterForMarqueOrCollection({
                marqueId: selectedMarqueCollection.value,
                collectionId: selectedCollection?.value
            }))
    }, [selectedMarqueCollection, selectedCollection, dispatch])

    return (
        <>
            <Grid
                container
            >
                <Grid xs={4}>
                    <h6>Groupe</h6>
                    <MarqueGroupSelection marqueCollections={marqueEditorialList}
                                          groups={adGroupList.map((e) => e.name)}
                    />

                </Grid>
                <Divider orientation="vertical" flexItem style={{marginLeft: 15, marginRight: 15}}/>
                <Grid xs={7}>
                    <Grid item>
                        <h6> Association Elib</h6>
                    </Grid>
                    <Grid item>
                        <MarqueElibSelection elibs={elibs}
                        />
                    </Grid>
                </Grid>
            </Grid>

            <Divider style={{marginTop: 15, marginBottom: 15}}/>

            <Grid
                container
            >
                <Grid container style={{height: 55}} direction="row" justify="space-between">
                    <Grid item>
                        <h6> Association Marque Editoriale</h6>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                <MarqueCollectionSelection marqueCollections={collectionList}
                                           selectedMarqueCollection={selectedMarqueCollection}
                                           setSelectedMarqueCollection={setSelectedMarque}
                                           setSelectedCollection={setSelectedColl}
                                           selectedCollection={selectedCollection}
                                           children={
                                               status !== 'NONE' &&
                                               <div className={classes.wrapper}>
                                                   <Tooltip title={"Les paramètres sont à jour"}>
                                                       <Fab size={"small"}
                                                            aria-label="save"
                                                            style={{
                                                                cursor: "default",
                                                                background: status === 'SAVED' ? green[500] : "#3f51b5"
                                                            }}
                                                       >
                                                           {status === 'SAVED' ? <CheckIcon/> : <SaveIcon/>}
                                                       </Fab>
                                                   </Tooltip>
                                                   {status === 'IN_PROGRESS' &&
                                                   <Tooltip title={"Sauvegarde en cours"}>
                                                       <CircularProgress size={45} className={classes.fabProgress}/>
                                                   </Tooltip>}
                                               </div>
                                           }
                />

                {selectedMarqueCollection && <Grid container style={{marginTop: 30}}>
                    <NBComplement marqueId={selectedMarqueCollection.value}
                                  collectionId={selectedCollection?.value}/>
                    <NumberParameter marqueId={selectedMarqueCollection.value}
                                     collectionId={selectedCollection?.value}
                                     parameterAttribute={'nbrJoursVerifComplCyc'}
                                     label={'Nombre de jours avant la mise en vente pour la vérification Cyclade'}/>
                    <NumberParameter marqueId={selectedMarqueCollection.value}
                                     collectionId={selectedCollection?.value}
                                     parameterAttribute={'nbrJoursVerifComplElib'}
                                     label={'Nombre de jours avant la mise en vente pour la vérification Elib'}/>
                </Grid>
                }
                </Grid>

                <Grid item xs={6}>

                </Grid>
            </Grid>

        </>
    )
}
