import {from, Observable, of} from "rxjs";
import {catchError, debounceTime, filter, map, mergeMap} from "rxjs/operators";
import {ofType, StateObservable} from "redux-observable";
import {State} from "../reducer";
import {
    filterOuvrage,
    isRefreshElibOuvrageList,
    getMevDatesSuccess,
    getOuvrage,
    ouvrageError,
    getRunsSuccess,
    isGetRunsAction
} from "../action/ouvrageAction";
import {Ean, EanList, MevDates} from "../types";
import Fuse from "fuse.js";
import {readQueryParams, searchParam} from "./searchEpic";
import {EpicDependencies} from "../store";
import {AxiosInstance} from "axios";

function getMevDates(axios: AxiosInstance, token?: string): Observable<MevDates> {
    return from(
        axios.get("/ouvrages/mev-dates", {
            headers: {
                'Authorization': 'Bearer ' + token
            },
        })
    ).pipe(
        map(({data}) => data)
    )
}

export function refreshListOnSearchTextUpdate(action$: Observable<any>, state$: StateObservable<State>) {
    return action$.pipe(
        ofType("SET_SEARCH_FULL_TEXT"),
        map((data) => {
            let opts = {
                shouldSort: false,
                isCaseSensitive: false,
                ignoreLocation: true,
                threshold: 0,
                keys: [
                    "title", "ean", "mevDate"
                ]
            };
            const ouvrageInit = state$.value.ouvrages.ouvragesInit;
            if (ouvrageInit.length < 1) {
                return []
            }
            if (data.payload === "") {
                return ouvrageInit
            }
            let fuse = new Fuse(ouvrageInit, opts);
            return fuse.search(data.payload);
        }),
        debounceTime(1000),
        map((data) => filterOuvrage(data))
    );
}


export function getMevDatesEpic(action$: Observable<any>, state$: StateObservable<State>,  dependencies: EpicDependencies) {
    const {axios} = dependencies

    return action$.pipe(
        ofType("GET_MEV_DATES"),
        mergeMap(() => {
            let token = state$.value.auth.token;
            return getMevDates(axios, token)
        }),
        map((data) => getMevDatesSuccess(data)),
        catchError(() => of(ouvrageError()))
    );
}


export function getRunsEpic(action$: Observable<any>, state$: StateObservable<State>,  dependencies: EpicDependencies) {
    const {axios} = dependencies

    return action$.pipe(
        filter(isGetRunsAction),
        mergeMap((data) => {
            let token = state$.value.auth.token;
            return getRuns(axios, data.payload, token)
        }),
        map((data) => getRunsSuccess(data))
    );
}

function getRuns(axios: AxiosInstance, removeExcluded: boolean, token?: string): Observable<number[]> {
    return from(
        axios.get(`/ouvrages/runs?removeExcluded=${removeExcluded}`, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
        })
    ).pipe(
        map(({data}) => data)
    )
}

export function refreshOuvrageList(action$: Observable<any>, state$: StateObservable<State>, dependencies: EpicDependencies) {
    const {history, axios} = dependencies;

    return action$.pipe(
        ofType("REFRESH_OUVRAGE_LIST"),
        mergeMap(({payload}) => {
            let token = state$.value.auth.token;
            const eanList = {
                eanList: payload.map((x: string) => {
                    return {ean: x} as Ean
                })
            } as EanList
            return refreshOuvragesList(axios, eanList, token).then(_ => {
                const queryFilters = readQueryParams(searchParam, history.location.search);
                return getOuvrage(queryFilters)
            })
        }),
        catchError(() => of(ouvrageError()))
    );
}

export function refreshCycladeOuvrageList(action$: Observable<any>, state$: StateObservable<State>, dependencies: EpicDependencies) {
    const {history, axios} = dependencies;

    return action$.pipe(
        ofType("REFRESH_CYCLADE_OUVRAGE_LIST"),
        mergeMap(({payload}) => {
            let token = state$.value.auth.token;
            const eanList = {
                eanList: payload.map((x: string) => {
                    return {ean: x} as Ean
                })
            } as EanList
            return checkOuvragesList(axios, eanList, token).then(_ => {
                const queryFilters = readQueryParams(searchParam, history.location.search);
                return getOuvrage(queryFilters)
            })
        }),
        catchError(() => of(ouvrageError()))
    );
}


export function refreshElibOuvrageList(action$: Observable<any>, state$: StateObservable<State>, dependencies: EpicDependencies) {
    const {history, axios} = dependencies;

    return action$.pipe(
        filter(isRefreshElibOuvrageList),
        mergeMap(({payload}) => {
            let token = state$.value.auth.token;
            const eanList = {
                eanList: payload.map((x: string) => {
                    return {ean: x} as Ean
                })
            } as EanList

            return checkOuvragesElibList(axios, eanList, token).then(_ => {
                const queryFilters = readQueryParams(searchParam, history.location.search);
                return getOuvrage(queryFilters)
            })
        }),
        catchError(() => of(ouvrageError()))
    );
}

export function refreshOuvragesList(axios: AxiosInstance, eanList: EanList, token?: string) {
    return axios.post("/ouvrages/refresh", eanList, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }
    )

}

export function checkOuvragesList(axios: AxiosInstance, eanList: EanList, token?: string) {
    return axios.post("/ouvrages/check", eanList, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }
    )
}

export function checkOuvragesElibList(axios: AxiosInstance, eanList: EanList, token?: string) {
    return axios.post("/ouvrages/check/elib", eanList, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }
    )
}
