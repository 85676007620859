import {createActionWithoutPayload, createActionWithPayload} from "./utils";
import {Filter, MevDates, Ouvrage} from "../types";


export const [updateSearchQueryParam, isUpdateSearchQueryParamAction] = createActionWithPayload<{ field: string, value: any }>('UPDATE_SEARCH_QUERY_PARAM');

export const [refreshOuvrageList, isRefreshOuvrageList] = createActionWithPayload<string[]>('REFRESH_OUVRAGE_LIST');
export const [refreshCycladeOuvrageList, isRefreshCycladeOuvrageList] = createActionWithPayload<string[]>('REFRESH_CYCLADE_OUVRAGE_LIST');
export const [refreshElibOuvrageList, isRefreshElibOuvrageList] = createActionWithPayload<string[]>('REFRESH_ELIB_OUVRAGE_LIST');

export const [getOuvrage, isGetOuvrageAction ] = createActionWithPayload<Filter>('GET_OUVRAGE');
export const [filterOuvrage, isfilterOuvrageAction ] = createActionWithPayload<Ouvrage[]>('FILTER_OUVRAGE');

export const [ouvrageError, isOuvrageError ] = createActionWithoutPayload('OUVRAGE_ERROR');
export const [getOuvrageSuccess, isGetOuvrageSuccess ] = createActionWithPayload<Ouvrage[]>('GET_OUVRAGE_SUCCESS');
export const [setOuvragesLoading, isSetOuvrageLoadingAction]= createActionWithPayload<boolean>('SET_OUVRAGES_LOADING');
export const [setCount, isSetCountAction]= createActionWithPayload<number>('SET_COUNT');
export const [setSearchFullText, isSetSearchFullTextAction]= createActionWithPayload<string>('SET_SEARCH_FULL_TEXT');

export const [getRuns, isGetRunsAction ] = createActionWithPayload<boolean>('GET_RUNS');
export const [getRunsSuccess, isGetRunsSuccessAction ] = createActionWithPayload<number[]>('GET_RUNS_SUCCESS');


export const [getMevDates, isGetMevDatesAction ] = createActionWithoutPayload('GET_MEV_DATES');
export const [mevDatesError, isMevDatesError ] = createActionWithoutPayload('MEV_DATES_ERROR');
export const [getMevDatesSuccess, isGetMevDatesSuccess ] = createActionWithPayload<MevDates>('GET_MEV_DATES_SUCCESS');
export const [setMevDates, isSetMevDatesAction ] = createActionWithPayload<MevDates>('SET_MEV_DATES');
