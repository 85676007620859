import Keycloak from "keycloak-js";
import {Dispatch, Middleware, MiddlewareAPI} from "redux";
import {Action, createActionWithPayload, createActionWithoutPayload} from "./action/utils";

type KeycloakUser = {
  preferred_username: string
  sub: string
  name?: string
  given_name?: string
  family_name?: string
  email?: string
}

export type User = {
  username: string
  firstname?: string
  lastname?: string
  email?: string
}

type AuthPayload = {
  token?: string
  roles: string[]
}

export const [authSuccess, isAuthSuccessAction ] = createActionWithPayload<AuthPayload>('KEYCLOAK_AUTH_SUCCESS');
export const [authError, isAuthErrorAction ] = createActionWithoutPayload('KEYCLOAK_AUTH_ERROR');
export const [userInfo, isUserInfoAction ] = createActionWithPayload<User>('KEYCLOAK_USER_INFO');
export const [refreshSuccess, isRefreshSuccessAction ] = createActionWithPayload<AuthPayload>('KEYCLOAK_TOKEN_REFRESH_SUCCESS');
export const [refreshError, isRefreshErrorAction ] = createActionWithoutPayload('KEYCLOAK_TOKEN_REFRESH_ERROR');
export const [logout, isLogoutAction ] = createActionWithoutPayload('KEYCLOAK_LOGOUT');


export const keycloakMiddleware: Middleware = (api: MiddlewareAPI) => {
  const keycloak = Keycloak<'native'>('/keycloak.json');

  keycloak.onAuthSuccess = () => {
    const payload: AuthPayload = {
      token: keycloak.token,
      roles: keycloak.realmAccess?.roles ?? [],
    };
    api.dispatch(authSuccess(payload));
    keycloak.loadUserInfo()
      .then(() => {
        const {
          preferred_username: username,
          given_name: firstname,
          family_name: lastname,
          email
        } = keycloak.userInfo as KeycloakUser;
        const user: User = {
          username,
          firstname,
          lastname,
          email
        };
        api.dispatch(userInfo(user));
      })
  };
  keycloak.onAuthError = () => {
    api.dispatch(authError());
  };
  keycloak.onAuthRefreshSuccess = () => {
    const payload: AuthPayload = {
      token: keycloak.token,
      roles: keycloak.realmAccess?.roles ?? []
    };
    api.dispatch(refreshSuccess(payload))
  };
  keycloak.onAuthRefreshError = () => {
    api.dispatch(refreshError());
  };
  keycloak.onTokenExpired = () => {
    keycloak.updateToken(5);
  };
  keycloak.onAuthLogout = () => {
    // api.dispatch(logout());
  };
  keycloak.onReady = (authenticated) => {
    if(!authenticated) {
      keycloak.login().then(() => console.log("login"));
    }
  };
  keycloak.init({
    promiseType: 'native',
  });

  return (next: Dispatch) => (action: Action) => {
    if(isLogoutAction(action)) {
      keycloak.logout({})
    }
    return next(action)
  }
};