//export const baseApiUrl = 'https://elib.hachette.contentside.io/elib/api';
//export const baseApiUrl = 'http://localhost:8080/elib/api';
export const baseApiUrl = '/elib/api';
export const pass = "42ee76a6-1f14-4e17-adac-ea8a496793c6";

export const ADMIN_ROLE = "ADMIN";

export const greenSmile = "smiley/Fichier 3.png";
export const redSmile = "smiley/Fichier 1.png";
export const pokerFace = "smiley/Fichier 2.png";
export const deadSmile = "smiley/Fichier 4.png";

