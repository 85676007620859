import * as React from "react";
import {useSelector} from "react-redux";
import {State} from "../reducer";
import 'react-block-ui/style.css';


export const OuvrageCount: React.FC = () => {
    const count = useSelector(({ouvrages}: State) => ouvrages.count);

    return (
        <div className="display-data">
                                    <span className="nb-label">
                                        Nombre de résultat(s) : {count}
                                    </span>
        </div>
    )
}
