import React from 'react';
import './App.css';
import {useSelector} from "react-redux";
import {State} from "./reducer";
import {RootConfig} from "./components/RoutConfig";
import 'bootstrap/dist/css/bootstrap.min.css';
import Notifier from "./components/Notifier";

const App: React.FC = () => {
    const auth = useSelector(({auth}: State) => auth);

    const isAuth = auth.authenticated;
    return isAuth ? (
        <div className="App">
            <Notifier/>
            <RootConfig/>
        </div>
    ) : (<div/>);
};

export default App;
