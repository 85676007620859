import {OptionsObject, SnackbarKey, SnackbarMessage} from "notistack";
import {createActionWithPayload} from "./utils";

export const [enqueueSnackbar, isEnqueueSnackbarAction] = createActionWithPayload<{
    key: SnackbarKey, notification:
        { message: SnackbarMessage, options: OptionsObject, closeable: boolean }
}>('ENQUEUE_SNACKBAR');

function generateUUID() { // Public Domain/MIT
    let d = new Date().getTime();//Timestamp
    let d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16;//random number between 0 and 16
        if(d > 0){//Use timestamp until depleted
            r = (d + r)%16 | 0;
            d = Math.floor(d/16);
        } else {//Use microseconds since page-load if supported
            r = (d2 + r)%16 | 0;
            d2 = Math.floor(d2/16);
        }
        return (c === 'x' ? r : ((r & 0x3) | 0x8)).toString(16);
    });
}

export const notify = (message: SnackbarMessage, options?: OptionsObject, closeable: boolean = false) => {
    const key = generateUUID();

    return enqueueSnackbar({
        key,
        notification: {
            message: message,
            options: {
                ...options,
                key,
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                },
                autoHideDuration: 3000
            },
            closeable,
        }
    })
}

export const notifyWarning = (message: string, closeable: boolean = false) =>
    notify(message, {variant: "warning"}, closeable)

export const notifyError = (message: string, closeable: boolean = false) =>
    notify(message, {variant: "error"}, closeable)

export const notifySuccess = (message: string, closeable: boolean = false) =>
    notify(message, {variant: "success"}, closeable)

export const [closeSnackbar, isCloseSnackbarAction] = createActionWithPayload<SnackbarKey | undefined>('CLOSE_SNACKBAR');

export const [removeSnackbar, isRemoveSnackbarAction] = createActionWithPayload<SnackbarKey>('REMOVE_SNACKBAR')