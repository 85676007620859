import {combineEpics} from 'redux-observable';
import {
    getMevDatesEpic,
    getRunsEpic,
    refreshCycladeOuvrageList,
    refreshListOnSearchTextUpdate,
    refreshOuvrageList,
    refreshElibOuvrageList
} from "./ouvrageEpic";
import {getOuvragesEpic, updateLocationOnQueryParamChange} from "./searchEpic";
import {getMarqueEditorialEpic, getMarqueEditorialWithCollectionsEpic, getDivisionsEpic} from "./marqueEditorialEpic";
import {getContactsEpic} from "./contactEpic";
import {getParamsEpic, getFtpEpic, getParamsMarqueCollectionEpic,updateParamsMarqueCollectionEpic, resetParamsMarqueCollectionEpic} from "./ParametrageEpic";
import {getElibEpic, getMarqueEditorialeForElibEpic, attachMarqueEditorialeToElib, dettachMarqueEditorialeToElib} from "./elibEpic"
import {getLogsEpic} from "./logEpic";
import {
    attachMarqueEditorialeToGroup,
    dettachMarqueEditorialeToGroup,
    getAdGroupEpic,
    getMarqueEditorialeForAdGroupEpic
} from "./adGroupEpic";


export const rootEpic = combineEpics(refreshElibOuvrageList, getElibEpic, resetParamsMarqueCollectionEpic, getMarqueEditorialeForElibEpic, attachMarqueEditorialeToElib, dettachMarqueEditorialeToElib, getRunsEpic, getContactsEpic, updateParamsMarqueCollectionEpic, getParamsEpic, getParamsMarqueCollectionEpic, getFtpEpic, getAdGroupEpic, getLogsEpic, getMevDatesEpic, getOuvragesEpic, refreshOuvrageList, refreshCycladeOuvrageList, updateLocationOnQueryParamChange, refreshListOnSearchTextUpdate,
    getMarqueEditorialEpic, getMarqueEditorialWithCollectionsEpic, getDivisionsEpic, getMarqueEditorialeForAdGroupEpic, dettachMarqueEditorialeToGroup, attachMarqueEditorialeToGroup);
