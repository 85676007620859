import {from, Observable, of} from "rxjs";
import {catchError, filter, map, mergeMap} from "rxjs/operators";
import {ofType, StateObservable} from "redux-observable";
import {State} from "../reducer";
import {
    adGroupError,
    getAdGroupSuccess,
    getMarqueEditorialForAdGroup,
    getMarqueEditorialForAdGroupError,
    getMarqueEditorialForAdGroupSuccess,
    isAttachMarqueEditorialeToAdGroup,
    isDettachMarqueEditorialeToAdGroup,
    isGetMarqueEditorialForAdGroup
} from "../action/adGroupAction";
import {AdGroup, Marque} from "../types";
import {ActionWithPayload} from "../action/utils";
import {AxiosInstance} from "axios";
import {EpicDependencies} from "../store";


function getAdGroup(axios: AxiosInstance, token?: string): Observable<AdGroup[]> {
    return from(
        axios.get<AdGroup[]>("/parametrage/adGroup", {
            headers: {
                'Authorization': 'Bearer ' + token
            },
        })
    ).pipe(
        map(({data}) => data)
    )
};

function getMarqueEditorialForAdGroupFunction(axios: AxiosInstance, adGroup: string, token?: string): Observable<Marque[]> {
    return from(
        axios.get<Marque[]>("/marqueEditorial/adGroup/" + adGroup, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
        })
    ).pipe(
        map(({data}) => data)
    )
};

function attachMarqueEditorialToAdGroup(axios: AxiosInstance, adGroup: string, marqueEditoriale: string, token?: string): Observable<ActionWithPayload> {
    return from(
        axios.put<Marque[]>("/marqueEditorial/" + marqueEditoriale + "/adGroup/" + adGroup, null, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
        })
    ).pipe(
        map((_) => getMarqueEditorialForAdGroup(adGroup))
    )
}

function dettachMarqueEditorialToAdGroup(axios: AxiosInstance, adGroup: string, marqueEditoriale: string, token?: string): Observable<ActionWithPayload> {
    return from(
        axios.delete<Marque[]>("/marqueEditorial/" + marqueEditoriale + "/adGroup/" + adGroup, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
        })
    ).pipe(
        map((_) => getMarqueEditorialForAdGroup(adGroup))
    )
}

export function getAdGroupEpic(action$: Observable<any>, state$: StateObservable<State>, dependencies: EpicDependencies) {
    const {axios} = dependencies
    return action$.pipe(
        ofType("GET_AD_GROUP"),
        mergeMap(() => {
            let token = state$.value.auth.token;
            return getAdGroup(axios, token)
        }),
        map((adGroup) => getAdGroupSuccess(adGroup)),
        catchError(() => of(adGroupError()))
    );
}

export function getMarqueEditorialeForAdGroupEpic(action$: Observable<any>, state$: StateObservable<State>, dependencies: EpicDependencies) {
    const {axios} = dependencies
    return action$.pipe(
        filter(isGetMarqueEditorialForAdGroup),
        mergeMap(({payload}) => {
            let token = state$.value.auth.token;
            return getMarqueEditorialForAdGroupFunction(axios, payload, token)
        }),
        map((adGroup) => getMarqueEditorialForAdGroupSuccess(adGroup)),
        catchError(() => of(getMarqueEditorialForAdGroupError()))
    );
}

export function attachMarqueEditorialeToGroup(action$: Observable<any>, state$: StateObservable<State>, dependencies: EpicDependencies) {
    const {axios} = dependencies

    return action$.pipe(
        filter(isAttachMarqueEditorialeToAdGroup),
        mergeMap(({payload}) => {
            let token = state$.value.auth.token;
            return attachMarqueEditorialToAdGroup(axios, payload.adGroup, payload.marqueId, token)
        })
    );
}


export function dettachMarqueEditorialeToGroup(action$: Observable<any>, state$: StateObservable<State>, dependencies: EpicDependencies) {
    const {axios} = dependencies

    return action$.pipe(
        filter(isDettachMarqueEditorialeToAdGroup),
        mergeMap(({payload}) => {
            let token = state$.value.auth.token;
            return dettachMarqueEditorialToAdGroup(axios, payload.adGroup, payload.marqueId, token)
        })
    );
}
