import { Ouvrage } from "../types"

const sizeSuffix = (size: 'small' | 'high' | 'medium') => {
    switch (size) {
        case "high":
            return '-X'
        case "small":
            return '-V'
        case 'medium':
            return '-G'
    }
}


const replaceImageSuffix = (url: string, size: 'small' | 'high' | 'medium') => {
    let sizeSuff = sizeSuffix(size)

    if (url.endsWith('-G.jpg')) {
        return url.replace('-G.jpg', sizeSuff + '.jpg')
    } else if (url.endsWith('-G.jpeg')) {
        return url.replace('-G.jpeg', sizeSuff + '.jpeg')
    } else if (url.endsWith('-G.png')) {
        return url.replace('-G.png', sizeSuff + '.png')
    } if (url.endsWith('-G.JPG')) {
        return url.replace('-G.JPG', sizeSuff + '.JPG')
    } else if (url.endsWith('-G.JPEG')) {
        return url.replace('-G.JPEG', sizeSuff + '.JPEG')
    } else if (url.endsWith('-G.PNG')) {
        return url.replace('-G.PNG', sizeSuff + '.PNG')
    } else {
        return url
    }
}

export const borderClass = (isVisuelProvisoire?: boolean) : string => {
    if (isVisuelProvisoire) {
        return 'orange-border'
    } else if (isVisuelProvisoire === false) {
        return 'green-border'
    } else {
        return ''
    }
}

export const showImage = (size: 'small' | 'high' | 'medium', url?: string, format?: string) => {
    if(url === undefined || url === null || url === "") {
        return "/images/icons8-file-delete-80.png"
    }

    if(format === undefined) {
        return replaceImageSuffix(url, size)
    }

    if(!format) {
        return "/images/icons8-file-100-3.png"
    }

    switch (format.toLowerCase()) {
        case "jpeg":
        case "jpg":
        case "png":
            return replaceImageSuffix(url, size)
        case "pdf":
            return "/images/icons8-pdf-100-4.png"
        case "mp3":
        case "wav":
            return "/images/icons8-fichier-audio-80.png"
        case "mp4":
        case "quicktime":
        case "m4v":
        case "mov":
            return "/images/icons8-video-file-100.png"
        case "lien":
            if(isComplementFeuilletage(url, format)) return "/images/icons8-livre-ouvert-100.png"
            else return "/images/icons8-lien-externe-100.png"
        default:
            return "/images/icons8-file-100-3.png"
    }
}

export const getTitleWithRevInfo = (ouvrage: Ouvrage) => ouvrage.remisEnVente ? `${ouvrage.title} (REV)` : ouvrage.title

export const isComplementFeuilletage = (url: string, format:string) => format === "lien" && url.startsWith("/document") && url.endsWith("/Feuilletage.pdf")
