import {Action} from "../action/utils";
import {Marque, Node} from "../types";
import {
    isGetMarqueEditorialSuccess,
    isGetMarqueEditorialAction,
    isSetCheckedMarqueAction,
    isGetMarqueEditorialWithCollectionsSuccess,
    isGetMarqueEditorialWithCollectionsAction,
    isGetDivisionsAction,
    isGetDivisionsSuccess
} from "../action/marqueEditorialAction";

type MarqueEditorialState = {
    marqueEditorial: Marque[],
    divisions: String[],
    collections: Node[],
    checkedMarques: string[],
    marqueEditorialLoading: boolean
    collectionsLoading: boolean
}

const init: MarqueEditorialState = {
    marqueEditorial: [],
    divisions: [],
    collections: [],
    marqueEditorialLoading: false,
    checkedMarques: [],
    collectionsLoading: false

}

export default (state = init, action: Action): MarqueEditorialState => {
    if (isGetMarqueEditorialSuccess(action)) {
        return {...state, marqueEditorial: action.payload, marqueEditorialLoading: false};
    } else if (isGetMarqueEditorialWithCollectionsSuccess(action)) {
        return {...state, collections: action.payload, collectionsLoading: false};
    } else if (isGetDivisionsSuccess(action)) {
        return {...state, divisions: action.payload, collectionsLoading: false};
    } else if (isGetMarqueEditorialAction(action)) {
        return {...state, marqueEditorialLoading: true};
    } else if (isGetMarqueEditorialWithCollectionsAction(action)) {
        return {...state, collectionsLoading: true};
    } else if (isGetDivisionsAction(action)) {
        return {...state, collectionsLoading: true};
    } else if (isSetCheckedMarqueAction(action)) {
        return {...state, checkedMarques: action.payload};
    } else {
        return state;
    }
}
