import {Action} from "../action/utils";

import {isGetContactAction, isGetContactSuccess, isSetContactAction} from "../action/contactAction";
import {ContactMarqueCollection} from "../types";

type ContactsState = {
    contacts: ContactMarqueCollection[],
}

const init: ContactsState = {
    contacts: [],
}

export default (state = init, action: Action): ContactsState => {
    if (isGetContactSuccess(action)) {
        return {...state, contacts: action.payload};
    } else if (isSetContactAction(action)) {
        return {...state, contacts: action.payload};
    } else if (isGetContactAction(action)) {
        return {...state};
    } else {
        return state;
    }
}