import {Action} from "../action/utils";

import {
    isfilterOuvrageAction,
    isGetMevDatesAction,
    isGetMevDatesSuccess,
    isGetOuvrageAction,
    isGetOuvrageSuccess,
    isSetCountAction,
    isSetMevDatesAction,
    isSetOuvrageLoadingAction,
    isSetSearchFullTextAction,
    isGetRunsSuccessAction, isOuvrageError
} from "../action/ouvrageAction";
import {MevDates, Ouvrage} from "../types";

type OuvragesState = {
    filterOuvrages: Ouvrage[],
    ouvragesInit: Ouvrage[],
    mevDates: MevDates,
    ouvrageLoading: boolean,
    count: number,
    searchFullText: string,
    runs: number[],
}

const init: OuvragesState = {
    filterOuvrages: [],
    ouvragesInit: [],
    ouvrageLoading: true,
    searchFullText: '',
    mevDates: {},
    count: 0,
    runs: [],
}

const sortedData = (ouvrages: Ouvrage[]) : Ouvrage[] => {
    return ouvrages.sort((a, b) =>
        new Date(a.mevDate).getTime() - new Date(b.mevDate).getTime()
    );
};

export default (state = init, action: Action): OuvragesState => {
    if (isSetOuvrageLoadingAction(action)) {
        return {...state, ouvrageLoading: action.payload};
    } else if (isSetCountAction(action)) {
        return {...state, count: action.payload};
    } else if (isGetRunsSuccessAction(action)) {
        return {...state, runs: action.payload};
    } else if (isSetMevDatesAction(action)) {
        return {...state, mevDates: action.payload};
    } else if (isGetMevDatesSuccess(action)) {
        return {...state, mevDates: action.payload};
    } else if (isGetMevDatesAction(action)) {
        return {...state};
    } else if (isGetOuvrageSuccess(action)) {
        return {
            ...state,
            ouvragesInit: sortedData(action.payload),
            filterOuvrages: sortedData(action.payload),
            ouvrageLoading: false,
            count: action.payload.length,
            searchFullText: ''
        };
    } else if(isOuvrageError(action)) {
        return {...state, ouvrageLoading: false}
    } else if (isSetSearchFullTextAction(action)) {
        return {...state, searchFullText: action.payload};
    } else if (isGetOuvrageAction(action)) {
        return {...state, ouvrageLoading: true};
    } else if (isfilterOuvrageAction(action)) {
        return {...state, filterOuvrages: action.payload, ouvrageLoading: false, count: action.payload.length};
    } else {
        return state;
    }
}
