import React, {useEffect, useMemo, useState} from 'react';
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import {Filter, Node} from "../types";
import {useDispatch} from "react-redux";
import {updateSearchQueryParam} from "../action/ouvrageAction";
import {readQueryParams, searchParam} from "../epic/searchEpic";
import {useLocation} from "react-router-dom";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import BlockUi from 'react-block-ui';
import { FormGroup } from 'react-bootstrap';
import FormLabel from '@material-ui/core/FormLabel';


interface Props {
    list: Node[]
    collectionsLoading: boolean
    className: string
    field: "marqueEditoriale" | "division"
    label: string
}

export const MarqueOrDivisionFilter: React.FC<Props> = (props) => {
    const searchLocation = useLocation().search;
    const checked = useMemo(() => readQueryParams(searchParam, searchLocation), [searchLocation]) as Filter
    const dispatch = useDispatch();
    const init: string[] = [];
    const [expanded, setExpanded] = useState(init);
    const [selectAll, setSelectAll] = useState(false);

    useEffect(() => {
        const allChecked = checked[props.field] && checked[props.field].length === props.list.length;
        setSelectAll(allChecked);
      }, [checked, props.list, props.field]);

    const handleCheck = (checkedItems: string[]) => {
        setSelectAll(checkedItems.length === props.list.length);

        dispatch(updateSearchQueryParam({
            field: props.field, 
            value: checkedItems
        }))
    };

    const handleSelectAllChange = () => {
        const updatedCheckboxes = !selectAll ? props.list.map(
            (marque) => marque.value
        ) : [];
        setSelectAll(!selectAll);
    
        dispatch(updateSearchQueryParam({
          field: props.field,
          value: updatedCheckboxes,
        }));
      };
    
      const indeterminate = checked[props.field] && checked[props.field].length > 0 && checked[props.field].length < props.list.length;

    return (
        <FormControl component="fieldset" className={props.className}>
            <BlockUi tag="div" blocking={props.collectionsLoading}>
                <FormGroup>
                <FormLabel component="legend" style={{marginBottom: 0}}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                indeterminate={indeterminate}
                                checked={selectAll}
                                onChange={handleSelectAllChange}
                            />
                        }
                        label={props.label}
                    />
                    </FormLabel>
                    <div className="marque-editorial">
                        <CheckboxTree
                            nodes={props.list}
                            checked={checked[props.field]}
                            onCheck={checked => handleCheck(checked)}
                            expanded={expanded}
                            onExpand={ex => setExpanded(ex)}
                        />
                    </div>
                </FormGroup>
            </BlockUi>
        </FormControl>
    )
}
