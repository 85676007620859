import * as React from "react";
import {createStyles, Theme} from '@material-ui/core/styles';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import makeStyles from "@material-ui/core/styles/makeStyles";
import 'date-fns';
import {useDispatch} from "react-redux";
import {updateSearchQueryParam} from "../action/ouvrageAction";
import {useLocation} from "react-router-dom";
import {readQueryParams, searchParam} from "../epic/searchEpic";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: '2px 0',
        },
        checkBox: {
            padding: '0 .2rem 0 .5rem',
            fontSize: '4px'
        },
    }),
);


interface Props {
    type: 'cyclade' | 'elib'
}

export const CycladeFilter: React.FC<Props> = ({type}: Props) => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const searchLocation = useLocation().search;
    const queryFilters = readQueryParams(searchParam, searchLocation);
    const capitalizeFirstLetter = (string: string) => string.charAt(0).toUpperCase() + string.slice(1);


    const handleChangeStatus = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked)
            dispatch(updateSearchQueryParam({
                field: type, value: [...queryFilters[type], name]
            }))
        else {
            dispatch(updateSearchQueryParam({
                field: type, value: queryFilters[type].filter((x: string) => x !== name)
            }))
        }
    };


    const availableEtat = ["Non valide", "Non vérifié", "Valide"]

    return (
        <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend" style={{marginBottom: 3, paddingTop: 10}}>Validation {capitalizeFirstLetter(type)}</FormLabel>
            <FormGroup>
                <div>
                    {availableEtat.map(x =>
                        <FormControlLabel
                            key={x}
                            control={<Checkbox color="primary" className={classes.checkBox}
                                               onChange={handleChangeStatus(x)}
                                               checked={queryFilters[type]?.indexOf(x) > -1} size="small"/>}
                            label={x}
                        />
                    )}


                </div>
            </FormGroup>
        </FormControl>
    )
};
