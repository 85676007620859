import React from 'react';
import {createStyles, Theme} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useDispatch, useSelector} from "react-redux";
import {State} from "../reducer";
import {ExitToApp} from "@material-ui/icons";
import {logout} from "../keycloak";
import HeaderMenu from "./HeaderMenu";

const useStyles = makeStyles((theme: Theme) =>

    createStyles({
        root: {
            flexGrow: 1,
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        title: {
            flexGrow: 1,
            textAlign: 'center',
        },
        partner: {
            flexGrow: 1,
            fontSize: 12,
            color: 'lightgrey',
        },
        logoImage: {
            height:40,
            paddingLeft:10,
        },
        logoTitle: {
            cursor: 'default',
            height:50,
            filter: 'invert(100%)'
        }
    }),
);

const Header: React.FC = () => {
    const classes = useStyles();
    const auth = useSelector(({auth}: State) => auth);
    const dispatch = useDispatch();

    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Toolbar>
                    <HeaderMenu/>
                    <Typography variant="h6" className={classes.title}>
                        <img className={classes.logoTitle} alt="" src="https://upload.wikimedia.org/wikipedia/fr/thumb/f/f7/Logo_Hachette_Livre_Actuel.svg/1280px-Logo_Hachette_Livre_Actuel.svg.png"/>
                    </Typography>
                    <Typography variant="h6" className={classes.partner}>
                    <div onClick={() => window.location.href = 'mailto:cyclade@hachette-livre.fr'}>
                        <label>Données issues du référentiel Hachette Livre</label>
                        <img className={classes.logoImage} alt="" src="/images/logo_cyclade.png"/>
                    </div>
                    </Typography>
                    <Typography variant="h6" className={classes.partner}> 
                    <div onClick={() => window.location.href = 'mailto:scoutifyteam@hachette-livre.fr'}>
                        <label>Données des e-librairies fournies par l'équipe Scoutify</label>
                        <img className={classes.logoImage} alt="" src="/images/logo_scoutify.png"/>
                    </div>

                    </Typography>
                    {auth.authenticated && (
                        <div>
                            <IconButton
                                color="inherit">
                                <AccountCircle/> <label className="header-label">{auth?.user?.firstname}</label>
                            </IconButton>
                            <IconButton
                                onClick={() => dispatch(logout())}
                                color="inherit"
                            >
                                <ExitToApp/>
                                <label className="header-label">Déconnexion</label>
                            </IconButton>
                        </div>
                    )}
                </Toolbar>
            </AppBar>
        </div>
    );
}
export default Header;