import * as React from "react";

import 'date-fns';
import MenuItem from "@material-ui/core/MenuItem";
import {useDispatch} from "react-redux";
import {updateMarqueEditorialeParameter} from '../../action/parametrageAction'
import {ParamsMarqueCollection} from "../../types";
import Select from "@material-ui/core/Select";


interface Props {
    defaultParams: ParamsMarqueCollection,
    value: string
}

export const NBComplementOperator: React.FC<Props> = ({value, defaultParams}) => {
    const dispatch = useDispatch();

    return (
        <Select value={value}
                onChange={(event) => {
                    const newParam = {
                        ...defaultParams,
                        operatorComplement: event.target.value as string
                    }
                    dispatch(updateMarqueEditorialeParameter(newParam))
                }}
        >
            <MenuItem value={"="}>{"="}</MenuItem>
            <MenuItem value={">="}>{">="}</MenuItem>
        </Select>
    )
};
