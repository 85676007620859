import * as React from "react";
import {useState} from "react";
import Modal from 'react-bootstrap/Modal';
import {borderClass, showImage} from "../utils/ouvrageUtils";

interface Props {
    cover: string,
    isVisuelProvisoire?: boolean
}


export const ModalImage: React.FC<Props> = ({cover, isVisuelProvisoire}: Props) => {
    const [delayHandler, setDelayHandler] = useState<any>();

    const handleMouseEnter = (cover: string) => {
        setDelayHandler(setTimeout(() => {
            handleShow(cover)
        }, 2400))
    }

    const handleMouseLeave = () => {
        if (delayHandler)
            clearTimeout(delayHandler)
    }


    const [show, setShow] = useState(false);
    const [img, setImg] = useState("");
    const handleClose = () => setShow(false);
    const handleShow = (src: string) => {
        setShow(true);
        setImg(showImage("medium", src));
    };



    return (<>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton/>
                <Modal.Body>
                    <img
                        alt=""
                        style={{width: '100%'}}
                        src={img}
                    />
                </Modal.Body>
            </Modal>
            <img
                alt=""
                onMouseEnter={() => {
                    if (cover !== "") handleMouseEnter(cover)
                }}
                onMouseLeave={() => {
                    if (cover !== "") handleMouseLeave()
                }}
                onClick={() => {
                    if (cover !== "") handleShow(cover)
                }}
                style={{height: 60}}
                src={showImage('small', cover)}
                className={cover === "" ? "no-cursor " : "" + borderClass(isVisuelProvisoire)}
            />
        </>
    )
};
