import {Filter, Ouvrage} from "../types";
import {EMPTY, from, merge, Observable, of, throwError} from "rxjs";
import {AxiosInstance} from "axios";
import {catchError, filter, map, mergeMap} from "rxjs/operators";
import {StateObservable} from "redux-observable";
import {State} from "../reducer";
import {EpicDependencies} from "../store";
import {
    getOuvrageSuccess,
    isGetOuvrageAction,
    isUpdateSearchQueryParamAction,
    ouvrageError
} from "../action/ouvrageAction";
import {Action, ActionWithPayload} from "../action/utils";
import {notifyError} from "../action/notification.actions";

export const searchParam: string[] = ["startDate", "endDate", "marqueEditoriale", "collectionEditoriale", "division", "run", "etat", "cyclade", "elib"]


export function readStringArrayParam(attr: string, params: URLSearchParams): object {
    return {
        [attr]: params.has(attr) ? params.get(attr)!.split('|||').map(x => decodeURIComponent(x)) : []
    };
}

export function readNumberArrayParam(attr: string, params: URLSearchParams): object {
    return {
        [attr]: params.has(attr) ? params.get(attr)!.split('|||').map(x => parseInt(decodeURIComponent(x))) : []
    };
}


export function readStringParam(attr: string, params: URLSearchParams): object {
    return params.has(attr) ? {[attr]: params.get(attr)} : {};
}


function buildStringParam(attr: string, val: any): string[] {
    return [`${attr}=${encodeURIComponent(val)}`];
}

function buildStringArrayParam(attr: string, val: any): string[] {
    return val?.length ? [`${attr}=${encodeURIComponent(val.join('|||'))}`] : [];
}

export function readQueryParams(searchParam: string[], search: string): any {
    const query = new URLSearchParams(search);
    return searchParam
        .map((attr) => {
            if (attr === 'startDate' || attr === 'endDate') {
                return readStringParam(attr, query)
            } else if (attr === 'run') {
                return readNumberArrayParam(attr, query)
            } else {
                return readStringArrayParam(attr, query)
            }
        })
        .reduce((prev, curr) => ({...prev, ...curr}), {});
}

// @ts-ignore
export function buildQueryParams(searchParam: string[], query: any): string {
    const v = searchParam
        .filter((attr) => query[attr] !== undefined)
        .flatMap((attr) => {
            if (attr === 'startDate' || attr === 'endDate') {
                return buildStringParam(attr, query[attr])
            } else {
                return buildStringArrayParam(attr, query[attr])
            }
        })
        .join('&');
    return `?${v}`;
}


function getOuvrages(axios: AxiosInstance, filter: Filter, token?: string): Observable<Ouvrage[]> {
    return from(
        axios.post<Ouvrage[]>("/ouvrages/", {...filter}, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
        })
    ).pipe(
        map(({data}) => data),
        catchError(err => throwError(err))
    )
}


export function updateLocationOnQueryParamChange(action$: Observable<ActionWithPayload>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {history} = dependencies;
    action$.pipe(
        filter(isUpdateSearchQueryParamAction)
    ).subscribe(({payload}) => {
        const {field, value} = payload;
        const former = readQueryParams(searchParam, history.location.search);
        const filters = {
            ...former,
            [field]: value
        }
        const query = buildQueryParams(searchParam, filters);
        history.replace(query);
    });
    return EMPTY
}


export function getOuvragesEpic(action$: Observable<ActionWithPayload>, state$: StateObservable<State>, dependencies: EpicDependencies) {
    const {axios} = dependencies;
    return action$.pipe(
        filter(isGetOuvrageAction),
        mergeMap(({payload}) =>
            getOuvrages(axios, payload, state$.value.auth.token).pipe(
                map(data => getOuvrageSuccess(data)),
                catchError(err => merge(of(ouvrageError()),
                    of(notifyError("Une erreur est survenue. Veuillez recharger la page ou modifier les filtres."))))
            )
        )
    );
}
