import {createActionWithoutPayload, createActionWithPayload} from "./utils";
import { Ftp, Params, ParamsMarqueCollection} from "../types";


export const [getFtp, isGetFtpAction] = createActionWithoutPayload('GET_FTP');
export const [ftpError, isFtpError] = createActionWithoutPayload('FTP_ERROR');
export const [getFtpSuccess, isGetFtpSuccess] = createActionWithPayload<Ftp>('GET_FTP_SUCCESS');

export const [getParams, isGetParams] = createActionWithoutPayload('GET_PARAMS');
export const [paramsError, isParamsError] = createActionWithoutPayload('PARAMS_ERROR');
export const [getParamsSuccess, isParamsSuccess] = createActionWithPayload<Params>('GET_PARAMS_SUCCESS');


export const [loadParameterForMarqueOrCollection, isLoadParameterForMarqueOrCollection] = createActionWithPayload<{ marqueId: string, collectionId?: string }>('LOAD_PARAMETER_FOR_MARQUE_OR_COLLECTION');
export const [loadParameterForMarqueOrCollectionSuccess, isLoadParameterForMarqueOrCollectionSuccess] = createActionWithPayload<ParamsMarqueCollection | undefined>('LOAD_PARAMETER_FOR_MARQUE_OR_COLLECTION_SUCCESS');
export const [loadParameterForMarqueOrCollectionError, isLoadParameterForMarqueOrCollectionError] = createActionWithoutPayload('LOAD_PARAMETER_FOR_MARQUE_OR_COLLECTION_ERROR');

export const [updateMarqueEditorialeParameter, isUpdateMarqueEditorialeParameter] = createActionWithPayload<ParamsMarqueCollection>('UPDATE_MARQUE_EDITORIALE_PARAMETER');
export const [resetMarqueEditorialeParameter, isResetMarqueEditorialeParameter] = createActionWithPayload<ParamsMarqueCollection>('RESET_MARQUE_EDITORIALE_PARAMETER');
