import * as React from "react";

import 'date-fns';
import {useDispatch, useSelector} from "react-redux";
import {State} from "../../reducer";
import {ParamsMarqueCollection} from "../../types";
import {Grid} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import {resetMarqueEditorialeParameter, updateMarqueEditorialeParameter} from "../../action/parametrageAction";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";


interface Props {
    marqueId: string,
    collectionId?: string,
    label: string,
    parameterAttribute: 'nbrJoursVerifComplElib' | 'nbrJoursVerifComplCyc'
}

export const NumberParameter: React.FC<Props> = ({marqueId, collectionId, label, parameterAttribute}) => {

    const defaultParameter = {
        marque: marqueId,
        collection: collectionId ?? ''
    } as ParamsMarqueCollection
    const dispatch = useDispatch();

    const parameter = useSelector(({parametrage}: State) => parametrage.currentParamsMarqueCollection ?? defaultParameter)

    return (<Grid container spacing={2}>
            <Grid item>
                {label}
            </Grid>
            <Grid item>
                <TextField
                    type="number" InputProps={{
                    inputProps: {
                        max: 300, min: 0
                    }
                }}
                    style={{width: 50}}
                    value={parameter[parameterAttribute] ?? ''} onChange={(event) => {
                    const newParam = {
                        ...defaultParameter,
                        [parameterAttribute]: parseInt(event.target.value)
                    }
                    dispatch(updateMarqueEditorialeParameter(newParam))
                }
                }
                />
            </Grid>
            {(parameter && (parameter[parameterAttribute] || parameter[parameterAttribute] === 0)) ?
                <Grid item>
                    <IconButton color="primary" aria-label="Effacer" component="span" size={"small"}
                                onClick={() => {
                                    dispatch(resetMarqueEditorialeParameter(
                                        {
                                            ...parameter,
                                            [parameterAttribute]: null
                                        }
                                    ))
                                }}>
                        <ClearIcon/>
                    </IconButton>
                </Grid> : <></>}

        </Grid>
    )
}
;
