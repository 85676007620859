import {Action} from "../action/utils";

import {isGetElibsAction, isGetElibsSuccess, isGetMarqueEditorialForElibSuccess} from "../action/elibAction";
import {Elib, MarqueCollection} from "../types";

type ElibState = {
    elib: Elib[],
    status: 'SAVED' | 'IN_PROGRESS' | 'NONE' | 'ERROR'
    marqueCollectionForElib: MarqueCollection[]
}

const init: ElibState = {
    elib: [],
    status: 'NONE',
    marqueCollectionForElib: []
};

export default (state = init, action: Action): ElibState => {
    if (isGetElibsSuccess(action)) {
        return {...state, elib: action.payload};
    } else if(isGetMarqueEditorialForElibSuccess(action)) {
        return {...state, marqueCollectionForElib: action.payload.sort(function (a, b) {
                const aSize = a.marqueLabel;
                const bSize = b.marqueLabel;
                const aLow = a.collectionLabel;
                const bLow = b.collectionLabel;

                if(aSize === bSize)
                {
                    if(aLow === null) return -1
                    return (aLow < bLow) ? -1 : (aLow > bLow) ? 1 : 0;
                }
                else
                {
                    return (aSize < bSize) ? -1 : 1;
                }
            })};
    } else if (isGetElibsAction(action)) {
        return {...state,};
    } else {
        return state;
    }
}
