import {from, Observable, of} from "rxjs";
import {catchError, map, mergeMap} from "rxjs/operators";
import {ofType, StateObservable} from "redux-observable";
import {State} from "../reducer";
import {CollectionNode, Marque, Node} from "../types";
import {
    getMarqueEditorialSuccess,
    getDivisionsSuccess,
    getDivisionsError,
    getMarqueEditorialWithCollectionsError,
    getMarqueEditorialWithCollectionsSuccess,
    marqueEditorialError
} from "../action/marqueEditorialAction";
import {AxiosInstance} from "axios";
import {EpicDependencies} from "../store";

function compare(a: string, b: string) {
    return a.localeCompare(b);
}

function compareMarque(a: Marque, b: Marque) {
    return compare(a.label, b.label)
}

function compareCollectionNode(a: CollectionNode, b: CollectionNode) {
    return compare(a.label, b.label)
}

function compareNode(a: Node, b: Node) {
    return compare(a.label, b.label)
}

function getMarqueEditorial(axios: AxiosInstance, token?: string): Observable<Marque[]> {
    return from(
        axios.get<Marque[]>("/marqueEditorial/", {
            headers: {
                'Authorization': 'Bearer ' + token
            },
        })
    ).pipe(
        map(({data}) => {
            data.sort(compareMarque)
            return data
        })
    )
}

function getDivisions(axios: AxiosInstance, token?: string): Observable<String[]> {
    return from(
        axios.get<String[]>("/marqueEditorial/divisions", {
            headers: {
                'Authorization': 'Bearer ' + token
            },
        })
    ).pipe(
        map(({data}) => {
            return data
        })
    )
}

function getMarqueEditorialWithCollections(axios: AxiosInstance, token?: string): Observable<Node[]> {
    return from(
        axios.get<Node[]>("/marqueEditorial/collections", {
            headers: {
                'Authorization': 'Bearer ' + token
            },
        })
    ).pipe(
        map(({data}) => {
            data.sort(compareNode)
            data.forEach(x => {
                if (x.children && x.children.length > 0) {
                    x.children.sort(compareCollectionNode)
                }
            })
            return data;
        })
    )
}

export function getMarqueEditorialEpic(action$: Observable<any>, state$: StateObservable<State>, dependencies: EpicDependencies) {
    const {axios} = dependencies
    return action$.pipe(
        ofType("GET_MARQUE_EDITORIAL"),
        mergeMap(() => {
            let token = state$.value.auth.token;
            return getMarqueEditorial(axios, token)
        }),
        map((marqueEditorial) => getMarqueEditorialSuccess(marqueEditorial)),
        catchError(() => of(marqueEditorialError()))
    );
}

export function getMarqueEditorialWithCollectionsEpic(action$: Observable<any>, state$: StateObservable<State>, dependencies: EpicDependencies) {
    const {axios} = dependencies
    return action$.pipe(
        ofType("GET_MARQUE_EDITORIAL_WITH_COLLECTIONS"),
        mergeMap(() => {
            let token = state$.value.auth.token;
            return getMarqueEditorialWithCollections(axios, token)
        }),
        map((marqueEditorial) => getMarqueEditorialWithCollectionsSuccess(marqueEditorial)),
        catchError(() => of(getMarqueEditorialWithCollectionsError()))
    );
}

export function getDivisionsEpic(action$: Observable<any>, state$: StateObservable<State>, dependencies: EpicDependencies) {
    const {axios} = dependencies
    return action$.pipe(
        ofType("GET_DIVISIONS"),
        mergeMap(() => {
            let token = state$.value.auth.token;
            return getDivisions(axios, token)
        }),
        map((divisions) => getDivisionsSuccess(divisions)),
        catchError(() => of(getDivisionsError()))
    );
}
