import {Action} from "../action/utils";

import {
    isGetFtpAction,
    isGetFtpSuccess,
    isGetParams,
    isUpdateMarqueEditorialeParameter,
    isLoadParameterForMarqueOrCollectionError,
    isLoadParameterForMarqueOrCollectionSuccess,
    isParamsSuccess,
    isResetMarqueEditorialeParameter
} from "../action/parametrageAction";
import {Ftp, Params, ParamsMarqueCollection} from "../types";

type FtpsState = {
    ftp?: Ftp,
    params?: Params,
    currentParamsMarqueCollection?: ParamsMarqueCollection,
    status: 'SAVED' | 'IN_PROGRESS' | 'NONE' | 'ERROR' | 'LOADED'
}

const init: FtpsState = {
    status: 'NONE'
};

export default (state = init, action: Action): FtpsState => {
    if (isGetFtpSuccess(action)) {
        return {...state, ftp: action.payload, status: 'LOADED'};
    } else if (isLoadParameterForMarqueOrCollectionSuccess(action)) {
        return {...state, currentParamsMarqueCollection: action.payload, status: 'SAVED'};
    } else if (isUpdateMarqueEditorialeParameter(action) || isResetMarqueEditorialeParameter(action)) {
        return {...state, status: 'IN_PROGRESS'};
    } else if (isLoadParameterForMarqueOrCollectionError(action)) {
        return {...state, currentParamsMarqueCollection: undefined, status: 'ERROR'};
    } else if (isParamsSuccess(action)) {
        return {...state, params: action.payload, status: 'LOADED'};
    } else if (isGetParams(action)) {
        return {...state, status: 'IN_PROGRESS'};
    } else if (isGetFtpAction(action)) {
        return {...state, status: 'IN_PROGRESS'};
    } else {
        return state;
    }
}
