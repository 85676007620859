import {createActionWithoutPayload, createActionWithPayload} from "./utils";
import {AdGroup, Marque} from "../types";


export const [getAdGroup, isGetAdGroupAction ] = createActionWithoutPayload('GET_AD_GROUP');
export const [adGroupError, isAdGroupError ] = createActionWithoutPayload('AD_GROUP_ERROR');
export const [getAdGroupSuccess, isGetAdGroupSuccess ] = createActionWithPayload<AdGroup[]>('GET_AD_GROUP_SUCCESS');


export const [attachMarqueEditorialeToAdGroup, isAttachMarqueEditorialeToAdGroup] = createActionWithPayload<{marqueId: string, adGroup: string}>('ATTACH_MARQUE_EDITORIALE_TO_AD_GROUP');
export const [dettachMarqueEditorialeToAdGroup, isDettachMarqueEditorialeToAdGroup] = createActionWithPayload<{marqueId: string, adGroup: string}>('DETTACH_MARQUE_EDITORIALE_TO_AD_GROUP');


export const [getMarqueEditorialForAdGroup, isGetMarqueEditorialForAdGroup ] = createActionWithPayload<string>('GET_MARQUE_EDITORIALE_FOR_AD_GROUP');
export const [getMarqueEditorialForAdGroupError, isGetMarqueEditorialForAdGroupError ] = createActionWithoutPayload('GET_MARQUE_EDITORIALE_FOR_AD_GROUP_ERROR');
export const [getMarqueEditorialForAdGroupSuccess, isGetMarqueEditorialForAdGroupSuccess ] = createActionWithPayload<Marque[]>('GET_MARQUE_EDITORIALE_FOR_AD_GROUP_SUCCESS');
