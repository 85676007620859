import React from 'react';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import {createStyles, Theme} from '@material-ui/core/styles';
import ListIcon from '@material-ui/icons/List';
import SettingsIcon from '@material-ui/icons/Settings';
import makeStyles from "@material-ui/core/styles/makeStyles";
import ListAltIcon from '@material-ui/icons/ListAlt';
import {useSelector} from "react-redux";
import {State} from "../reducer";
import {ADMIN_ROLE} from "../const";
import {Link as RouterLink} from "react-router-dom";
import Link from "@material-ui/core/Link";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        paper: {
            marginRight: theme.spacing(2),
        },
        link: {
        }
    }),
);

export default function HeaderMenu() {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const auth = useSelector(({auth}: State) => auth);

    const handleClick = (event : any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div className={classes.root}>
            <div>
                <Button aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={(event) => handleClick(event)}
                        color="inherit"
                >
                    <ListIcon/> <label style={{cursor: 'pointer'}}>Menu</label>
                </Button>
                <Paper>
                    <Menu id="simple-menu" anchorEl={anchorEl}
                          keepMounted
                          open={Boolean(anchorEl)}
                          onClose={handleClose}>
                        <MenuItem onClick={handleClose}>
                            <label>
                                <Link
                                    to={`/`}  color={"inherit"} className={classes.link}
                                    component={RouterLink} underline={"none"}>
                                    <ListAltIcon style={{marginRight: 5}}/>
                                    Liste Ouvrages
                                </Link>
                            </label>
                        </MenuItem>
                        {
                            auth.roles.indexOf(ADMIN_ROLE) !== -1 &&

                            <MenuItem onClick={handleClose}>
                                <label>
                                    <Link
                                        to={`/parametre`} color={"inherit"}
                                        component={RouterLink} underline={"none"}>
                                        <SettingsIcon style={{marginRight: 5}}/>
                                        Paramétrage
                                    </Link>
                                </label>
                            </MenuItem>
                        }
                    </Menu>
                </Paper>
            </div>
        </div>
    );
}
