import {createActionWithoutPayload, createActionWithPayload} from "./utils";
import {Marque, Node} from "../types";


export const [getMarqueEditorial, isGetMarqueEditorialAction] = createActionWithoutPayload('GET_MARQUE_EDITORIAL');
export const [marqueEditorialError, isMarqueEditorialError] = createActionWithoutPayload('MARQUE_EDITORIAL_ERROR');
export const [getMarqueEditorialSuccess, isGetMarqueEditorialSuccess] = createActionWithPayload<Marque[]>('GET_MARQUE_EDITORIAL_SUCCESS');

export const [getMarqueEditorialWithCollections, isGetMarqueEditorialWithCollectionsAction] = createActionWithoutPayload('GET_MARQUE_EDITORIAL_WITH_COLLECTIONS');
export const [getMarqueEditorialWithCollectionsError, isGetMarqueEditorialWithCollectionsError] = createActionWithoutPayload('GET_MARQUE_EDITORIAL_WITH_COLLECTIONS_ERROR');
export const [getMarqueEditorialWithCollectionsSuccess, isGetMarqueEditorialWithCollectionsSuccess] = createActionWithPayload<Node[]>('GET_MARQUE_EDITORIAL_WITH_COLLECTIONS_SUCCESS');

export const [getDivisions, isGetDivisionsAction] = createActionWithoutPayload('GET_DIVISIONS');
export const [getDivisionsError, isGetDivisionsError] = createActionWithoutPayload('GET_DIVISIONS_ERROR');
export const [getDivisionsSuccess, isGetDivisionsSuccess] = createActionWithPayload<String[]>('GET_DIVISIONS_SUCCESS');

export const [setCheckedMarque, isSetCheckedMarqueAction] = createActionWithPayload<string[]>('SET_CHECKED_MARQUE');
