import {from, Observable, of} from "rxjs";
import {catchError, filter, map, mergeMap} from "rxjs/operators";
import {StateObservable} from "redux-observable";
import {State} from "../reducer";
import {Elib, ElibWithMarque} from "../types";
import {ActionWithPayload} from "../action/utils";
import {
    getElibsError,
    getElibsSuccess,
    getMarqueEditorialForElib,
    getMarqueEditorialForElibError,
    getMarqueEditorialForElibSuccess,
    isAttachMarqueEditorialeToElib,
    isDettachMarqueEditorialeToElib,
    isGetElibsAction,
    isGetMarqueEditorialForElib
} from "../action/elibAction";
import {AxiosInstance} from "axios";
import {EpicDependencies} from "../store";


function getElibAction(axios: AxiosInstance, token?: string): Observable<Elib[]> {

    return from(
        axios.get("/elibs", {
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }
        )
    ).pipe(
        map(({data}) => data)
    )
}


function getMarqueEditorialForElibFunction(axios: AxiosInstance, elibId: number, token?: string): Observable<ElibWithMarque> {
    return from(
        axios.get<ElibWithMarque>("/elibs/" + elibId, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
        })
    ).pipe(
        map(({data}) => data)
    )
}

function attachMarqueEditorialToElib(axios: AxiosInstance, elibId: number, marqueId: string, collectionId?: string, token?: string): Observable<ActionWithPayload> {
    return from(
        axios.post("/elibs/attach/" + elibId, {
            marqueId: marqueId,
            collectionId: collectionId
        }, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
    ).pipe(
        map((_) => getMarqueEditorialForElib(elibId))
    )
}

function dettachMarqueEditorialToElib(axios: AxiosInstance, elibId: number, marqueId: string, collectionId?: string, token?: string): Observable<ActionWithPayload> {
    return from(
        axios.post("/elibs/dettach/" + elibId, {
            marqueId: marqueId,
            collectionId: collectionId
        }, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
        })
    ).pipe(
        map((_) => getMarqueEditorialForElib(elibId))
    )
}


export function getMarqueEditorialeForElibEpic(action$: Observable<any>, state$: StateObservable<State>, dependencies: EpicDependencies) {
    const {axios} = dependencies
    return action$.pipe(
        filter(isGetMarqueEditorialForElib),
        mergeMap(({payload}) => {
            let token = state$.value.auth.token;
            return getMarqueEditorialForElibFunction(axios, payload, token)
        }),
        map((marqueCollection) => getMarqueEditorialForElibSuccess(marqueCollection.marqueCollections)),
        catchError(() => of(getMarqueEditorialForElibError()))
    );
}

export function attachMarqueEditorialeToElib(action$: Observable<any>, state$: StateObservable<State>, dependencies: EpicDependencies) {
    const {axios} = dependencies
    return action$.pipe(
        filter(isAttachMarqueEditorialeToElib),
        mergeMap(({payload}) => {
            let token = state$.value.auth.token;
            return attachMarqueEditorialToElib(axios, payload.elibId, payload.marqueId, payload.collectionId, token)
        })
    );
}


export function dettachMarqueEditorialeToElib(action$: Observable<any>, state$: StateObservable<State>, dependencies: EpicDependencies) {
    const {axios} = dependencies

    return action$.pipe(
        filter(isDettachMarqueEditorialeToElib),
        mergeMap(({payload}) => {
            let token = state$.value.auth.token;
            return dettachMarqueEditorialToElib(axios, payload.elibId, payload.marqueId, payload.collectionId, token)
        })
    );
}


export function getElibEpic(action$: Observable<any>, state$: StateObservable<State>, dependencies: EpicDependencies) {
    const {axios} = dependencies

    return action$.pipe(
        filter(isGetElibsAction),
        mergeMap(() => {
            let token = state$.value.auth.token;
            return getElibAction(axios, token)
        }),
        map((data) => getElibsSuccess(data)),
        catchError(() => of(getElibsError()))
    )
}

