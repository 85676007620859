import * as React from "react";
import {useState} from "react";
import 'date-fns';
import TextField from "@material-ui/core/TextField";
import {Autocomplete} from "@material-ui/lab";
import {useDispatch, useSelector} from "react-redux";
import {State} from "../../reducer";
import Grid from "@material-ui/core/Grid";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {CollectionNode, Elib, Node} from "../../types";
import {
    attachMarqueEditorialeToElib,
    dettachMarqueEditorialeToElib,
    getMarqueEditorialForElib,
    getMarqueEditorialForElibSuccess
} from '../../action/elibAction'
import {MarqueCollectionSelection} from "./MarqueCollectionSelection";
import {Button, createStyles} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import makeStyles from "@material-ui/core/styles/makeStyles";

interface Props {
    elibs: Elib[]
}

const useStyles = makeStyles(() =>
    createStyles({
        scroll: {
            height: 400,
            overflowY: 'scroll'
        }
    }),
);

export const MarqueElibSelection: React.FC<Props> = (props) => {
    const {elibs} = props;
    const collectionList = useSelector(({marqueEditorial}: State) => marqueEditorial.collections);

    const marqueForElib = useSelector(({elib}: State) => elib.marqueCollectionForElib);

    const [selectedElib, setSelectedElib] = useState<Elib | null>(null);
    const dispatch = useDispatch()

    let [selectedMarqueCollection, setSelectedMarque] = useState<Node | null>();
    let [selectedCollection, setSelectedColl] = useState<CollectionNode | null>();

    const classes = useStyles();

    return (
        <>
            <Grid
                container style={{marginTop: 37}}
                direction="row"
            >
                <Autocomplete style={{width: 200}}
                              options={elibs}
                              getOptionLabel={(option) => option.label}
                              onChange={(event: any, newValue: Elib | null) => {
                                  if (newValue === null) {
                                      dispatch(getMarqueEditorialForElibSuccess([]))
                                  } else {
                                      dispatch(getMarqueEditorialForElib(newValue.id))
                                  }
                                  setSelectedElib(newValue)
                              }}
                              renderInput={(params) => <TextField {...params} label="Groupe" variant="outlined"/>}
                />

            </Grid>
            <Grid
                container style={{marginTop: 15}}
                direction="row"
            >

                {selectedElib &&
                <Grid xs={12} className={classes.scroll}>
                    <Table style={{marginTop: 15}}>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{width: 300}}>Marque éditoriale</TableCell>
                                <TableCell style={{width: 300}}>Collection</TableCell>
                                <TableCell align={"center"}>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell colSpan={2}>

                                    <MarqueCollectionSelection marqueCollections={collectionList}
                                                               selectedMarqueCollection={selectedMarqueCollection!!}
                                                               setSelectedMarqueCollection={setSelectedMarque}
                                                               setSelectedCollection={setSelectedColl}
                                                               selectedCollection={selectedCollection!!}
                                    />
                                </TableCell>
                                <TableCell align={"center"}>
                                    {selectedMarqueCollection &&
                                    <Button
                                        onClick={() => {
                                            dispatch(attachMarqueEditorialeToElib({
                                                marqueId: selectedMarqueCollection!!.value,
                                                collectionId: selectedCollection?.value.split(".")[1],
                                                elibId: selectedElib!!.id
                                            }));
                                            setSelectedMarque(null)
                                            setSelectedColl(null)

                                        }
                                        }
                                        color="inherit"
                                    >
                                        Ajouter
                                    </Button>
                                    }
                                </TableCell>
                            </TableRow>
                            {marqueForElib && marqueForElib.map(x =>
                                <TableRow>

                                    <TableCell>
                                        {x.marqueLabel}
                                    </TableCell>
                                    <TableCell>
                                        {x.collectionLabel}
                                    </TableCell>


                                    <TableCell align={"center"}>
                                        <label htmlFor="icon-button-file">
                                            <DeleteIcon className="icon"
                                                        onClick={() => dispatch(dettachMarqueEditorialeToElib({
                                                            marqueId: x.marqueId,
                                                            collectionId: x.collectionId,
                                                            elibId: selectedElib!!.id
                                                        }))}/>
                                        </label>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>


                </Grid>
                }

            </Grid>
        </>
    )
}
