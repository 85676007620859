import {combineReducers} from "redux";
import auth from "./auth";
import ouvrages from "./ouvrages";
import marqueEditorial from "./marqueEditorial";
import notification from "./notification";
import contacts from "./contacts";
import parametrage from "./parametrage";
import logs from "./logs";
import adGroup from "./adGroup";
import elib from "./elib";

const root = combineReducers({
  auth,
  ouvrages,
  marqueEditorial,
  contacts,
  parametrage,
  logs,
  adGroup,
  elib,
  notification
});

export type State = ReturnType<typeof root>

export default root;
