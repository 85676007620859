import * as React from "react";
import {useState} from "react";
import Modal from 'react-bootstrap/Modal';
import {isComplementFeuilletage, showImage} from "../utils/ouvrageUtils";
import {Complement} from "../types";
import {baseApiUrl} from "../const"
import { Tooltip } from "@material-ui/core";

interface Props {
    complement: Complement
}


export const ModalComplementImage: React.FC<Props> = ({complement}: Props) => {

    const getThumbnailComplementUrl = () => {
        return baseApiUrl + "/images/complement/" + complement.id + "." + complement.format
    }

    const isClickable = (format?: string) => {
        return (format === null || format === undefined || format.toLowerCase() === 'png' || format.toLowerCase() === 'jpg' || format.toLowerCase() === 'jpeg')
    }


    const [show, setShow] = useState(false);
    const [img, setImg] = useState("");
    const handleClose = () => setShow(false);
    const handleShow = (src: string) => {
        setShow(true);
        setImg(src);
    };

    const displayComplement = (publicationOnline: boolean, extractOnline: boolean, format?: string, url?: string) => {
        if (format === null) {
            return <img
                className={`complement-thumb  ${publicationOnline && extractOnline ? "green-border" : "orange-border"} " no-cursor`}
                src={showImage('small', getThumbnailComplementUrl(), format)}
                alt={""}/>
        } else if (isClickable(format)) {
            return <img
                key={complement.id}
                onClick={() => {
                    handleShow(complement.url)
                }}
                className={`complement-thumb  ${publicationOnline && extractOnline ? "green-border" : "orange-border"}`}
                src={showImage('small', getThumbnailComplementUrl(), format)}
                alt={""}/>
        } else if(url && format && isComplementFeuilletage(url,format)) {
            return  <img
            key={complement.id}
            className={`complement-thumb  ${publicationOnline && extractOnline ? "green-border" : "orange-border"} " no-cursor`}
            src={showImage('small', url, format)}
            alt={""}/>
        } else {
            return <a href={complement.url} target={"_blank"} rel="noopener noreferrer">
                <img
                    key={complement.id}
                    className={`complement-thumb  ${publicationOnline && extractOnline ? "green-border" : "orange-border"}`}
                    src={showImage('small', getThumbnailComplementUrl(), format)}
                    alt={""}/>
            </a>
        }
    }

    return (<>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton/>
                <Modal.Body>
                    <img
                        alt=""
                        style={{width: '100%'}}
                        src={img}
                    />
                </Modal.Body>
            </Modal>
            <Tooltip title={isComplementFeuilletage(complement.url, complement.format) ? "Feuilletage" : complement.name}>{displayComplement(complement.publicationOnline, complement.extractOnline, complement.format, complement.url)}</Tooltip>
        </>
    )
};
