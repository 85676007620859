import {from, Observable, of} from "rxjs";
import {AxiosInstance} from "axios";
import {catchError, filter, flatMap, map, mergeMap} from "rxjs/operators";
import {ofType, StateObservable} from "redux-observable";
import {State} from "../reducer";
import {
    Elib,
    Ftp,
    MailParams,
    MarqueCollectionElibParam,
    NbrDaysBeforAfterMve,
    Params,
    ParamsMarqueCollection
} from "../types";
import {
    ftpError,
    getFtpSuccess,
    getParamsSuccess,
    isLoadParameterForMarqueOrCollection,
    isResetMarqueEditorialeParameter,
    isUpdateMarqueEditorialeParameter,
    loadParameterForMarqueOrCollection,
    loadParameterForMarqueOrCollectionError,
    loadParameterForMarqueOrCollectionSuccess,
    paramsError
} from "../action/parametrageAction";
import {Action} from "../action/utils";
import {fromPromise} from "rxjs/internal-compatibility";
import {EpicDependencies} from "../store";


export function saveFtpParamAction(axios: AxiosInstance, ftp: Ftp, token?: string): any {
    return axios.post("/parametrage/ftp", ftp, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }
    );
}

export function saveElibAction(axios: AxiosInstance, elib: Elib, token?: string): any {
    return axios.post("/parametrage/elib", elib, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }
    );
}

export function deleteElibAction(axios: AxiosInstance, elib: Elib, token?: string): any {
    return axios.delete("/parametrage/elib/" + elib.id, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }
    );
}

export function saveCronTimeAction(axios: AxiosInstance, cronTime: string, token?: string): any {

    return axios.post("/parametrage/import-elib", {time: cronTime}, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }
    );

}

export function saveThumbnailBasePathAction(axios: AxiosInstance, basePath: string, token?: string): any {
    return axios.post("/parametrage/thumbnail-base-directory", {path: basePath}, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }
    );
}

export function saveMonthDateImportCycladeAction(axios: AxiosInstance, month: number, token?: string): any {
    return axios.post("/parametrage/nbrMonthImportCyclade", month, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }
    );
}


export function updateRunNumbersToHide(axios: AxiosInstance, runNumbers: number[], token?: string): any {
    return axios.post("/parametrage/run-numbers-to-hide", {
            runNumbersToHide: runNumbers
        }, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }
    );
}

export function saveNbrDaysBeforAfterMveAction(axios: AxiosInstance, before: number, after: number, token?: string): any {
    let params: NbrDaysBeforAfterMve = {
        before: before,
        after: after
    };
    return axios.post("/parametrage/nbrDaysBeforAfterMve", params, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }
    );
}


export function saveNbrsParamAction(axios: AxiosInstance, data: ParamsMarqueCollection, token?: string): any {
    return axios.post("/parametrage/nbr-complement", data, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }
    );
}

export function saveNbrsElibParamAction(axios: AxiosInstance, data: MarqueCollectionElibParam, token?: string): any {
    return axios.post("/parametrage/nbr-Jour-verif-elib", data, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }
    );
}

export function saveImportCycladeAction(axios: AxiosInstance, cronTime: string, token?: string): any {
    return axios.post("/parametrage/import-cyclade", {time: cronTime}, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }
    );
}


export function saveDeleteOldOuvrageAction(axios: AxiosInstance, cronTime: string, token?: string): any {
    return axios.post("/parametrage/delete-old-ouvrage", {time: cronTime}, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }
    );
}

export function saveCleanSftpAction(axios: AxiosInstance, cronTime: string, token?: string): any {
    return axios.post("/parametrage/delete-old-processed-file", {time: cronTime}, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }
    );
}

export function generateCompelementThumbnail(axios: AxiosInstance, token?: string): any {
    return axios.post("/parametrage/complement/generate_thumbnail", [], {
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }
    );
}

export function stopGenerationOfCompelementThumbnail(axios: AxiosInstance, token?: string): any {
    return axios.get("/parametrage/complement/generate_thumbnail/stop", {
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }
    );
}


export function saveSendEmailActiveAction(axios: AxiosInstance, params: MailParams, token?: string): any {
    return axios.post("/parametrage/mail-active", params, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }
    );
}


function getFtpParamAction(axios: AxiosInstance, token?: string): Observable<Action> {
    return fromPromise(axios.get<Ftp>("/parametrage/ftp/params", {
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }
    )).pipe(
        mergeMap((ftp) => {
                return of(getFtpSuccess(ftp.data))
            }
        ),
        catchError(err =>
            of(ftpError())
        )
    )
}

export function getFtpEpic(action$: Observable<any>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {axios} = dependencies
    return action$.pipe(
        ofType("GET_FTP"),
        flatMap(() => {
            let token = state$.value.auth.token;
            return getFtpParamAction(axios, token)
        })
    )
}


export function getParams(axios: AxiosInstance, token?: string): Observable<Params> {
    return from(
        axios.get("/parametrage", {
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }
        )
    ).pipe(
        map(({data}) => data)
    )
}

export function getParamsEpic(action$: Observable<any>, state$: StateObservable<State>, dependencies: EpicDependencies) {
    const {axios} = dependencies
    return action$.pipe(
        ofType("GET_PARAMS"),
        mergeMap(() => {
            let token = state$.value.auth.token;
            return getParams(axios, token)
        }),
        map((data) => getParamsSuccess(data)),
        catchError(() => of(paramsError()))
    )
}


function getParamsMarqueCollection(axios: AxiosInstance, marqueId: string, collectionId?: string, token?: string): Observable<ParamsMarqueCollection | undefined> {
    let url = "/parametrage/params-marque-collection/" + marqueId

    if (collectionId !== undefined) {
        url = url + "/" + collectionId
    }

    return from(
        axios.get(url, {
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }
        )
    ).pipe(
        map(({data}) => data === "" ? undefined : data)
    )
}

export function getParamsMarqueCollectionEpic(action$: Observable<any>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const { axios } = dependencies
    return action$.pipe(
        filter(isLoadParameterForMarqueOrCollection),
        mergeMap(({payload}) => {
            let token = state$.value.auth.token;
            return getParamsMarqueCollection(axios, payload.marqueId, payload.collectionId, token)
        }),
        map((data) => loadParameterForMarqueOrCollectionSuccess(data)),
        catchError(() => of(loadParameterForMarqueOrCollectionError()))
    )
}

export function updateParamsMarqueCollectionEpic(action$: Observable<any>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {axios} = dependencies
    return action$.pipe(
        filter(isUpdateMarqueEditorialeParameter),
        mergeMap(({payload}) => {
            let token = state$.value.auth.token;
            return updateOrResetParamsMarqueCollection(axios, payload, false, token)
        })
    )
}

export function resetParamsMarqueCollectionEpic(action$: Observable<any>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {axios} = dependencies
    return action$.pipe(
        filter(isResetMarqueEditorialeParameter),
        mergeMap(({payload}) => {
            let token = state$.value.auth.token;
            return updateOrResetParamsMarqueCollection(axios, payload, true, token)
        })
    )
}

function updateOrResetParamsMarqueCollection(axios: AxiosInstance, param: ParamsMarqueCollection, reset: boolean, token?: string) {
    return fromPromise(axios.post("/parametrage/params-marque-collection" + (reset ? "/reset" : ""), param, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }
    )).pipe(
        mergeMap((_) => {
                return of(loadParameterForMarqueOrCollection({marqueId: param.marque!!, collectionId: param.collection}))
            }
        )
    )
}
