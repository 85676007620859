import * as React from "react";
import {createStyles, Theme} from '@material-ui/core/styles';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import makeStyles from "@material-ui/core/styles/makeStyles";
import 'date-fns';
import {useDispatch} from "react-redux";
import {updateSearchQueryParam} from "../action/ouvrageAction";
import {useLocation} from "react-router-dom";
import {readQueryParams, searchParam} from "../epic/searchEpic";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: '2px 0',
        },
        checkBox: {
            padding: '0 .5rem 0 1rem',
            fontSize: '10px'
        },
    }),
);

export const EtatFilter: React.FC = () => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const searchLocation = useLocation().search;
    const queryFilters = readQueryParams(searchParam, searchLocation);


    const handleChangeStatus = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked)
            dispatch(updateSearchQueryParam({
                field: `etat`, value: [...queryFilters.etat, name]
            }))
        else {
            dispatch(updateSearchQueryParam({
                field: `etat`, value: queryFilters.etat.filter((x: string) => x !== name)
            }))
        }
    };


    const availableEtat = ["Prêt pour la mise en vente", "Mis en vente", "Codifié"]

    return (
        <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend">Etat </FormLabel>
                <FormGroup>
                    <div className="marque-editorial state">

                        {availableEtat.map(x =>
                            <FormControlLabel
                                key={x}
                                control={<Checkbox color="primary" className={classes.checkBox}
                                                   onChange={handleChangeStatus(x)}
                                                   checked={queryFilters?.etat?.indexOf(x) > -1} size="small"/>}
                                label={x}
                            />
                        )}


                    </div>
                </FormGroup>
        </FormControl>
    )
};
