import {Action} from "../action/utils";

import {
    isGetAdGroupAction,
    isGetAdGroupSuccess,
    isGetMarqueEditorialForAdGroupError,
    isGetMarqueEditorialForAdGroupSuccess
} from "../action/adGroupAction";
import {AdGroup, Marque} from "../types";

type AdGroupState = {
    adGroup: AdGroup[],
    marqueEditoriales: Marque[]
}

const init: AdGroupState = {
    adGroup: [],
    marqueEditoriales: []
};

export default (state = init, action: Action): AdGroupState => {
    if (isGetAdGroupSuccess(action)) {
        return {...state, adGroup: action.payload};
    } else if (isGetAdGroupAction(action)) {
        return {...state};
    } else if (isGetMarqueEditorialForAdGroupError(action)) {
        return {
            ...state,
            marqueEditoriales: []
        }
    } else if (isGetMarqueEditorialForAdGroupSuccess(action)) {
        return {
            ...state,
            marqueEditoriales: action.payload.sort(function(a, b){
                const first = a.label.toLowerCase()
                const second = b.label.toLowerCase()
                if(first < second) { return -1; }
                if(first > second) { return 1; }
                return 0;
            })
        }
    } else {
        return state;
    }
}
