import {Action} from "../action/utils";

import {isGetLogsAction, isGetLogsSuccess, isLogsError} from "../action/logsAction";
import {Logs} from "../types";

type LogsState = {
    logs: Logs[],
    inProgress: boolean
}

const init: LogsState = {
    logs: [],
    inProgress: false
};

export default (state = init, action: Action): LogsState => {
    if (isGetLogsSuccess(action)) {
        return {...state, logs: action.payload, inProgress: false};
    } else if (isLogsError(action)) {
        return {...state, logs: [], inProgress: false};
    } else if (isGetLogsAction(action)) {
        return {...state, inProgress: true};
    } else {
        return state;
    }
}
