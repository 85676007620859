import {from, Observable, of} from "rxjs";
import {AxiosInstance} from "axios";
import {catchError, map, mergeMap} from "rxjs/operators";
import {ofType, StateObservable} from "redux-observable";
import {State} from "../reducer";
import {contactError, getContactSuccess} from "../action/contactAction";
import {ContactDisplay, ContactMarqueCollection, ContactMarqueCollectionAdd} from "../types";
import {EpicDependencies} from "../store";


function getContacts(axios: AxiosInstance, token?: string): Observable<ContactMarqueCollection[]> {
    return from(
        axios.get<ContactMarqueCollection[]>("/contacts/", {
            headers: {
                'Authorization': 'Bearer ' + token
            },
        })
    ).pipe(
        map(({data}) => data)
    )
}

export function getContactsEpic(action$: Observable<any>, state$: StateObservable<State>, dependencies: EpicDependencies) {
    const { axios } = dependencies
    return action$.pipe(
        ofType("GET_CONTACT"),
        mergeMap(() => {
            let token = state$.value.auth.token;
            return getContacts(axios, token)
        }),
        map((contacts) => getContactSuccess(contacts)),
        catchError(() => of(contactError()))
    );
}

export function addContactAction(axios: AxiosInstance, contact: ContactMarqueCollectionAdd, token?: string): any {

    return axios.post("/contacts/add", contact, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }
    );
}

type ContactDelete = {
    email: string,
    firstname: string,
    marque: string,
    collection?: string
}

export function deleteContactAction(axios: AxiosInstance, contact: ContactDisplay, token?: string): any {

    let contactDelete: ContactDelete = {
        email: contact.email,
        firstname: contact.firstname,
        marque: contact.marque.value,
    };

    if (contact.collection) {
        contactDelete.collection = contact.collection.value;
    }

    return axios.post("/contacts/delete", contactDelete, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }
    );
}

