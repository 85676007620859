import {from, Observable, of} from "rxjs";
import {catchError, map, mergeMap} from "rxjs/operators";
import {ofType, StateObservable} from "redux-observable";
import {State} from "../reducer";
import {getLogsSuccess, logsError} from "../action/logsAction";
import {Logs} from "../types";
import {AxiosInstance} from "axios";
import {EpicDependencies} from "../store";


function getLogs(axios: AxiosInstance, token?: string): Observable<Logs[]> {
    return from(
        axios.get<Logs[]>("/logs/", {
            headers: {
                'Authorization': 'Bearer ' + token
            },
        })
    ).pipe(
        map(({data}) => data)
    )
}

export function getLogsEpic(action$: Observable<any>, state$: StateObservable<State>, dependencies: EpicDependencies) {
    const {axios} = dependencies
    return action$.pipe(
        ofType("GET_LOGS"),
        mergeMap(() => {
            let token = state$.value.auth.token;
            return getLogs(axios, token)
        }),
        map((logs) => getLogsSuccess(logs)),
        catchError(() => of(logsError()))
    );
}