import * as React from "react";
import 'date-fns';
import TextField from "@material-ui/core/TextField";
import {Autocomplete} from "@material-ui/lab";

import {CollectionNode, Node} from "../../types";
import {Grid} from "@material-ui/core";


interface Props {
    marqueCollections: Node[],
    selectedMarqueCollection?: Node,
    setSelectedMarqueCollection: Function,
    selectedCollection?: CollectionNode,
    setSelectedCollection: Function,
    children?: any
}

export const MarqueCollectionSelection: React.FC<Props> = (props) => {
    const {
        marqueCollections, selectedMarqueCollection, selectedCollection,
        setSelectedCollection, setSelectedMarqueCollection, children
    } = props;

    const getCollections = () => {
        if (selectedMarqueCollection) {
            return selectedMarqueCollection.children ?? []
        } else {
            return []
        }
    };

    return (
        <Grid container spacing={2}>
            <Grid item>
                <Autocomplete
                    style={{width: 270}}
                    options={marqueCollections}
                    getOptionLabel={(option) => option.label}
                    value={selectedMarqueCollection || null}
                    onChange={(event: any, newValue: Node | null | undefined) => {
                        setSelectedMarqueCollection(newValue);
                        setSelectedCollection(null);
                    }}
                    renderInput={(params) => <TextField {...params} label="Marque"
                                                        variant="outlined"/>}
                />
            </Grid>
            <Grid item>
                <Autocomplete
                    style={{width: 270}}
                    options={getCollections()}
                    getOptionLabel={(option) => option.label}
                    value={selectedCollection || null}
                    onChange={(event: any, newValue: CollectionNode | null | undefined) => {
                        setSelectedCollection(newValue);
                    }}
                    renderInput={(params) => <TextField error={false} {...params} label="Collection"
                                                        variant="outlined"/>}
                />
            </Grid>
            {children &&
            <Grid item>{children}</Grid>}
        </Grid>
    )
}
