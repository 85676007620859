import * as React from "react";
import {useEffect, useState} from "react";
import MaterialTable, {MTableToolbar} from 'material-table';
import {createMuiTheme, ThemeProvider} from '@material-ui/core/styles';
import {frFR} from '@material-ui/core/locale';
import Modal from 'react-bootstrap/Modal';
import Tooltip from "@material-ui/core/Tooltip";
import {OuvrageDetails} from "./OuvrageDetails";
import {Complement, Image, Ouvrage} from "../types";
import {deadSmile, greenSmile, pokerFace, redSmile} from "../const";
import {useDispatch, useSelector} from "react-redux";
import {State} from "../reducer";
import {refreshCycladeOuvrageList, refreshElibOuvrageList, refreshOuvrageList, setCount} from "../action/ouvrageAction";
import {formatDate} from "../utils/datFormat";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {createStyles} from "@material-ui/core";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {ModalImage} from "./ModalImage";
import {ModalComplementImage} from "./ModalComplementImage";
import Checkbox from "@material-ui/core/Checkbox";
import {getElibs} from "../action/elibAction";
import BlockUi from "react-block-ui";
import {OuvrageCount} from "./OuvragesCount";
import Paper from "@material-ui/core/Paper";
import { getTitleWithRevInfo } from "../utils/ouvrageUtils";

const theme = createMuiTheme({
    overrides: {
        MuiTableCell: {
            footer: {
                borderBottom: 'none'
            },
            root: {
                fontSize: '0.775rem',
                lineHeight: 1.23,
                padding: '8px'
            },
            head: {
                lineHeight: 1.3
            }
        }
    }
}, frFR);

const useStyles = makeStyles(() =>
    createStyles({
        table: {paddingTop: 70, fontWeight: 500}
    }),
);

const OuvrageTable: React.FC = () => {
    const dispatch = useDispatch();
    const tableRef = React.useRef<any>(null);
    const ouvrageDetailsRef = React.useRef<any>();
    const classes = useStyles();

    const ouvrageList = useSelector(({ouvrages}: State) => ouvrages.filterOuvrages);

    const loading = useSelector(({ouvrages}: State) => ouvrages.ouvrageLoading);

    const [show, setShow] = useState(false);
    const [img] = useState("");
    const handleClose = () => setShow(false);

    const getSmile = (value: string) => {
        switch (value) {
            case 'Non valide' :
                return redSmile;
            case 'Valide' :
                return greenSmile;
            case 'Non vérifié':
                return deadSmile;
            case 'Inconnu':
                return pokerFace;
        }
    };

    useEffect(() => {
        dispatch(getElibs());
    }, [dispatch])
    

    const handleClickForModal = (ouvrage: Ouvrage) => {
        ouvrageDetailsRef.current?.updateShowDetails(true);
        ouvrageDetailsRef.current?.updateSelectedOuvrage(ouvrage);
    }

    const columns = [
        {
            title: 'Couv',
            cellStyle: {
                padding: 'inherit',
            },
            field: 'cover',
            render: (rowData: { images: Image[] }) => {
                const cover = rowData?.images?.filter(x => x.type === 'GR1')[0];
                const backCover = rowData?.images?.filter(x => x.type === 'RE2')[0];

                return (<div style={{textAlign: 'center'}}>
                    <ModalImage cover={cover?.url} isVisuelProvisoire={cover?.isVisuelProvisoire}/>
                    {backCover &&
                    <span> <ModalImage cover={backCover.url} isVisuelProvisoire={backCover.isVisuelProvisoire}/></span>}
                </div>)
            },
        },
        {
            title: 'Flux',
            width: 30,
            cellStyle: {
                padding: 'inherit'
            },
            field: 'fluxLibrairie',
            render: (rowData: { fluxLibrairie: boolean }) => (
                <div style={{textAlign: 'center'}}>
                    <Checkbox color="primary"
                              checked={rowData.fluxLibrairie} size="small" disabled={true}/>
                </div>
            ),
        },
        {
            title: 'Titre',
            field: 'title',
            customSort: (data1: any, data2: any) => {
                return data1.title.localeCompare(data2.title)
            },
            render: (rowData: Ouvrage) => (
                <label style={{width: '12rem', cursor: 'pointer'}}
                       onClick={() => handleClickForModal(rowData)}>{getTitleWithRevInfo(rowData)}</label>
            ),
        },
        {
            title: 'Complément',
            field: 'complement',
            render: (rowData: { complement: Complement[] }) => (
                <div style={{width: '12rem', textAlign: 'center'}}>
                    {
                        rowData.complement ?
                            rowData.complement.sort((a,b) => a.name > b.name ? 1 : -1).map((complement, idx) => (
                                <ModalComplementImage key={'modal_' + complement.id} complement={complement}/>
                            )) : ""
                    }
                </div>
            ),
        },

        {title: 'EAN', field: 'ean'},
        {
            title: 'MEV',
            field: 'mevDate',
            render: (rowData: { mevDate: string }) => (
                <div><label>
                    {formatDate(new Date(rowData.mevDate))}
                </label></div>
            )
        },
        {
            title: 'RUN',
            field: 'run',
            width: 30,
            render: (rowData: { run: number }) => (
                <div><label>
                    {rowData.run}
                </label></div>
            )
        },
        {
            title: 'Prix',
            field: 'price',
            width: 30,
            render: (rowData: { price: number }) => (
                <div><label>
                    {rowData.price.toLocaleString('fr-FR', {
                        style: "currency",
                        currency: "EUR",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    })}
                </label></div>
            )
        },
        {title: 'Etat', field: 'status'},
        {
            title: 'Cyclade',
            field: 'statusCyclade',
            render: (rowData: { statusCyclade: string, msgStatusCyclade: string }) => (
                <Tooltip
                    title={rowData.statusCyclade + (rowData.msgStatusCyclade !== '' ? ' : ' + rowData.msgStatusCyclade : '')}
                    enterDelay={500}
                    leaveDelay={200}>
                    <img
                        alt=""
                        style={{height: 30}}
                        src={getSmile(rowData.statusCyclade)}
                    />
                </Tooltip>
            ),
        },
        {
            title: 'Elib',
            field: 'statusElib',
            render: (rowData: { statusElib: string, msgStatusElib: string }) => (
                <Tooltip title={<div className="content"
                                     dangerouslySetInnerHTML={{__html: rowData.statusElib + (rowData.msgStatusElib ? " : " + rowData.msgStatusElib : "")}}/>}
                         enterDelay={500}
                         leaveDelay={200}>
                    <img
                        alt=""
                        style={{height: 30}}
                        src={getSmile(rowData.statusElib)}
                    />
                </Tooltip>
            ),
        }
    ];
    const refreshList = (list: Ouvrage[] | Ouvrage) => {
        dispatch(refreshOuvrageList(Array.isArray(list) ? list.map(x => x.ean) : [list.ean]))
    };

    const checkList = (list: Ouvrage[] | Ouvrage) => {
        dispatch(refreshCycladeOuvrageList(Array.isArray(list) ? list.map(x => x.ean) : [list.ean]))
    };

    const checkElibList = (list: Ouvrage[] | Ouvrage) => {
        dispatch(refreshElibOuvrageList(Array.isArray(list) ? list.map(x => x.ean) : [list.ean]))
    };

    if (loading) {
        return (<BlockUi tag="div" blocking={true}>
            <div style={{height: 300}}/>
        </BlockUi>)
    } else if (ouvrageList && ouvrageList.length > 0) {
        return (
            <ThemeProvider theme={theme}>
                <MaterialTable
                    title=""
                    tableRef={tableRef}
                    columns={columns}
                    data={ouvrageList}
                    actions={[
                        {
                            icon: 'refresh',
                            tooltip: 'Réinitialiser les statuts Cyclade',
                            onClick: (event, rowData) => refreshList(rowData)
                        },
                        {
                            icon: 'check',
                            tooltip: 'Relancer la vérification Cyclade',
                            onClick: (event, rowData) => checkList(rowData)
                        },
                        {
                            icon: 'check',
                            tooltip: 'Relancer la vérification Elib',
                            onClick: (event, rowData) => checkElibList(rowData)
                        }
                    ]}
                    onSearchChange={() => {
                        dispatch(setCount(tableRef.current.dataManager.searchedData.length));
                    }}
                    onChangePage={()=> {
                        tableRef.current.tableContainerDiv.current.scroll(0, 0)
                        window.scrollTo(0, 0)
                    }}
                    options={{
                        selection: true,
                        actionsColumnIndex: -1,
                        searchFieldAlignment: 'left',
                        searchFieldStyle: {
                            width: '26rem',
                        },
                        headerStyle: {
                            textAlign: 'center'
                        },
                        search: true,
                        pageSize: 50,
                        showFirstLastPageButtons: true,
                        pageSizeOptions: [50, 60, 70, 80, 90, 100],
                        paginationType: 'stepped',
                        maxBodyHeight: "47rem",
                        minBodyHeight: "47rem",
                    }}
                    components={{
                        Toolbar: props => (
                            <>
                                <OuvrageCount />
                                <MTableToolbar {...props}>
                                </MTableToolbar>
                            </>
                        ),
                        Container: props => (
                            <Paper {...props} elevation={0}/>
                        )
                    }}
                    localization={{
                        pagination: {
                            labelDisplayedRows: '{from}-{to} de {count}',
                            labelRowsSelect: 'Lignes'
                        },
                        toolbar: {
                            nRowsSelected: '{0} ouvrage(s) sélectionné(s)',
                            searchPlaceholder: 'Recherche',
                            searchTooltip: 'Recherche'
                        },
                        header: {
                            actions: ''
                        },
                        body: {
                            emptyDataSourceMessage: 'Aucun enregistrement à afficher',
                            filterRow: {
                                filterTooltip: 'Filter'
                            }
                        }
                    }}
                />
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton/>
                    <Modal.Body>
                        <img
                            alt=""
                            style={{width: '100%'}}
                            src={img}
                        />
                    </Modal.Body>
                </Modal>
                <OuvrageDetails ref={ouvrageDetailsRef} />
            </ThemeProvider>
        )
    } else {
        return (<ThemeProvider theme={theme}>
            <TableContainer className={classes.table}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell padding="checkbox">

                            </TableCell>
                            {columns.map((e, index) => {
                                return <TableCell key={'table_cell_' + index}
                                                  padding="checkbox">{e.title}</TableCell>
                            })
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow style={{height: 100}}>
                            <TableCell colSpan={9} align={"center"}>
                                Aucun enregistrement à afficher
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </ThemeProvider>)
    }

};

export default React.memo(OuvrageTable);
