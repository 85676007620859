import * as React from "react";
import {createStyles, Theme} from '@material-ui/core/styles';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import makeStyles from "@material-ui/core/styles/makeStyles";
import 'date-fns';
import {useDispatch, useSelector} from "react-redux";
import {State} from "../reducer";
import BlockUi from "react-block-ui";
import {getRuns} from "../action/ouvrageAction";
import {updateSearchQueryParam} from "../action/ouvrageAction";
import {useLocation} from "react-router-dom";
import {readQueryParams, searchParam} from "../epic/searchEpic";
import {Autocomplete} from "@material-ui/lab";
import TextField from "@material-ui/core/TextField";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import {useEffect} from "react";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: '2px 0',
        },
        checkBox: {
            padding: '0 .5rem 0 1rem',
            fontSize: '10px'
        },
    }),
);

export const RunFilter: React.FC = () => {

    const classes = useStyles();
    const ouvrageLoading = useSelector(({ouvrages}: State) => ouvrages.ouvrageLoading);
    const dispatch = useDispatch();
    const searchLocation = useLocation().search;
    const queryFilters = readQueryParams(searchParam, searchLocation);
    const handleChangerun = (event: React.ChangeEvent<{}>, values: number | null | number[]) => {
        dispatch(updateSearchQueryParam({
            field: `run`, value: values
        }))
    };

    useEffect(() => {
        dispatch(getRuns(true))
    }, [dispatch])

    const runs = useSelector(({ouvrages}: State) => ouvrages.runs);


    const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
    const checkedIcon = <CheckBoxIcon fontSize="small"/>;

    return (
        <FormControl component="fieldset" className={classes.formControl} size={"small"}>
            <FormLabel component="legend">Run</FormLabel>
            <BlockUi tag="div" blocking={ouvrageLoading}>
                <FormGroup>
                    <div className="marque-editorial run">
                        <Autocomplete
                            multiple
                            size={"small"}
                            value={queryFilters?.run}
                            options={runs}
                            disableCloseOnSelect
                            renderOption={(option, {selected}) => (
                                <React.Fragment>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{marginRight: 8}}
                                        checked={selected}
                                    />
                                    {option}
                                </React.Fragment>
                            )}
                            onChange={(run, val) => handleChangerun(run, val)}
                            renderInput={(params) => (
                                <TextField {...params} variant="outlined"/>
                            )}
                        />
                    </div>
                </FormGroup>
            </BlockUi>
        </FormControl>
    )
};
