import * as React from "react";
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {State} from "../reducer";
import {CardContent} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import OuvrageTable from "./OuvrageTable";
import {getOuvrage} from "../action/ouvrageAction";
import {AdvancedSearch} from "./AdvancedSearch";
import 'react-block-ui/style.css';
import {useLocation} from "react-router-dom";
import {readQueryParams, searchParam} from "../epic/searchEpic";


export const OuvrageList: React.FC = () => {
    const dispatch = useDispatch();

    const mode = 'list';

    const ouvrageList = useSelector(({ouvrages}: State) => ouvrages.filterOuvrages);

    const searchLocation = useLocation().search;

    useEffect(() => {
        const queryFilters = readQueryParams(searchParam, searchLocation);
        const filters = {
            ...queryFilters,
        }
        dispatch(getOuvrage(filters))
    }, [dispatch, searchLocation])

    return (
        <div className="body-content">
            <Card>
                <CardContent style={{padding: 0}}>
                    <div className="content">
                        <div className="search">
                            <AdvancedSearch
                                ouvrages={ouvrageList}
                            />
                        </div>
                        <div className="list">
                            {
                                mode === 'list' && <OuvrageTable/>
                            }
                        </div>
                    </div>
                </CardContent>
            </Card>
        </div>
    )
}
