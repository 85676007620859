import {Action} from "../action/utils";

import {
  User,
  isAuthSuccessAction,
  isAuthErrorAction,
  isUserInfoAction,
  isRefreshSuccessAction,
  isRefreshErrorAction,
  isLogoutAction
} from "../keycloak"

type AuthState = {
  authenticated: boolean
  user?: User
  token?: string
  roles: string[]
}

const init: AuthState = {
  authenticated: false,
  roles: []
};

export default (state = init, action: Action ): AuthState => {
  if(isAuthSuccessAction(action)) {
    const {payload: {token, roles}} = action;
    return {...state, authenticated: true, token, roles};
  } else if(isAuthErrorAction(action)) {
    return init;
  }  else if(isUserInfoAction(action)) {
    const {payload: user} = action;
    return {...state, user};
  } else if(isRefreshSuccessAction(action)) {
    const {payload: {token, roles}} = action;
    return {...state, token, roles};
  } else if(isRefreshErrorAction(action)) {
    return init;
  } else if(isLogoutAction(action)) {
    return init;
  } else {
    return state;
  }
}