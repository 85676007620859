import * as React from "react";
import {useEffect} from "react";
import MaterialTable from "material-table";
import {useDispatch, useSelector} from "react-redux";
import {State} from "../reducer";
import {getLogs} from "../action/logsAction";
import BlockUi from "react-block-ui";


export const Monitoring: React.FC = (props) => {

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getLogs());
    }, [dispatch]);

    const logs = useSelector(({logs}: State) => logs.logs)
    const inProgress = useSelector(({logs}: State) => logs.inProgress)

    const getVerifCycladeLogs = () => {
        return logs.filter(log =>
            log.logType === "verif_cyclade"
        ).sort((data1: any, data2: any) => {
            return data2.id - data1.id;
        });
    };
    const getVerifElibLogs = () => {
        return logs.filter(log =>
            log.logType === "verif_elib"
        ).sort((data1: any, data2: any) => {
            return data2.id - data1.id;
        })
    };

    const displayDate = (date: Date): string => {
        return date.toLocaleDateString('fr-FR', {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        }) + ' à ' + date.toLocaleTimeString('fr-FR');
    }

    const getImportCycladeLogs = () => {
        return logs.filter(log =>
            log.logType === "import_cyclade"
        ).sort((data1: any, data2: any) => {
            return data2.id - data1.id;
        })
    };
    const getCommunLogs = () => {
        return logs.filter(log =>
            log.logType === "import_elib"
        ).sort((data1: any, data2: any) => {
            return data2.id - data1.id;
        })
    };

    const columns = [
        {title: 'Code Technique', field: 'categorie'},
        {title: 'Code Fonctionnel', field: 'name'},
        {title: 'Message', field: 'codeFontionnel'},
        {title: 'Nom de fichier', field: 'fileName'},
        {
            title: 'Date',
            field: 'date',
            render: (rowData: { date: Date }) => (
                <label>
                    {displayDate(new Date(rowData.date))}
                </label>
            )
        },
        {title: 'Utilisateur', field: 'owner'}
    ];

    return (
        <div className="monitoring-content">
            <h3 className="h3-title">Monitoring</h3>

            <BlockUi tag="div" blocking={inProgress}>
                <MaterialTable
                    title="Import des données Cyclade"
                    columns={columns}
                    data={getImportCycladeLogs()}
                    options={{
                        search: true
                    }}
                    localization={{
                        pagination: {
                            labelDisplayedRows: '{from}-{to} de {count}',
                            labelRowsSelect: 'Lignes'
                        },
                        toolbar: {
                            nRowsSelected: '{0} ligne(s) sélectionnée(s)',
                            searchPlaceholder: 'Recherche',
                            searchTooltip: 'Recherche'
                        },
                        header: {
                            actions: ''
                        },
                        body: {
                            emptyDataSourceMessage: 'Aucun enregistrement à afficher',
                            filterRow: {
                                filterTooltip: 'Filter'
                            }
                        }
                    }}
                />
                <MaterialTable
                    title="Vérification des données Cyclade"
                    columns={columns}
                    data={getVerifCycladeLogs()}
                    options={{
                        search: true
                    }}
                    localization={{
                        pagination: {
                            labelDisplayedRows: '{from}-{to} de {count}',
                            labelRowsSelect: 'Lignes'
                        },
                        toolbar: {
                            nRowsSelected: '{0} ligne(s) sélectionnée(s)',
                            searchPlaceholder: 'Recherche',
                            searchTooltip: 'Recherche'
                        },
                        header: {
                            actions: ''
                        },
                        body: {
                            emptyDataSourceMessage: 'Aucun enregistrement à afficher',
                            filterRow: {
                                filterTooltip: 'Filter'
                            }
                        }
                    }}
                />
                <MaterialTable
                    title="Vérification de données elibrairie"
                    columns={columns}
                    data={getVerifElibLogs()}
                    options={{
                        search: true
                    }}
                    localization={{
                        pagination: {
                            labelDisplayedRows: '{from}-{to} de {count}',
                            labelRowsSelect: 'Lignes'
                        },
                        toolbar: {
                            nRowsSelected: '{0} ligne(s) sélectionnée(s)',
                            searchPlaceholder: 'Recherche',
                            searchTooltip: 'Recherche'
                        },
                        header: {
                            actions: ''
                        },
                        body: {
                            emptyDataSourceMessage: 'Aucun enregistrement à afficher',
                            filterRow: {
                                filterTooltip: 'Filter'
                            }
                        }
                    }}
                />
                <MaterialTable
                    title="Import elibrairie"
                    columns={columns}
                    data={getCommunLogs()}
                    options={{
                        search: true
                    }}
                    localization={{
                        pagination: {
                            labelDisplayedRows: '{from}-{to} de {count}',
                            labelRowsSelect: 'Lignes'
                        },
                        toolbar: {
                            nRowsSelected: '{0} ligne(s) sélectionnée(s)',
                            searchPlaceholder: 'Recherche',
                            searchTooltip: 'Recherche'
                        },
                        header: {
                            actions: ''
                        },
                        body: {
                            emptyDataSourceMessage: 'Aucun enregistrement à afficher',
                            filterRow: {
                                filterTooltip: 'Filter'
                            }
                        }
                    }}
                />
            </BlockUi>
        </div>

    )
}
