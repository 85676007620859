import React from 'react';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import DashboardIcon from '@material-ui/icons/Dashboard';
import SettingsIcon from '@material-ui/icons/Settings';
import PermDataSettingIcon from '@material-ui/icons/PermDataSetting';
import {SystemParameter} from './SystemParameter';
import {ApplicationParameter} from "./ApplicationParameter";
import {Monitoring} from "./Monitoring";
import {Members} from "./Members";
import {Card, CardContent} from "@material-ui/core";
import {useSelector} from "react-redux";
import {State} from "../reducer";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Redirect} from "react-router";
import {ADMIN_ROLE} from "../const";
import {useHistory} from "react-router-dom";
import {MarqueEditorialeParameter} from "./MarqueEditorialeParameter";


const useStyles = makeStyles({
    root: {
        flexGrow: 1,
        margin: '1rem 3rem 1rem 3rem'
    },
    cardContent: {
        margin: '0 3rem 2rem 3rem'
    }
});

export const Parameter: React.FC = () => {
    const auth = useSelector(({auth}: State) => auth);
    const classes = useStyles();
    const history = useHistory();


    const tabNumberQuery = new URLSearchParams(history.location.search).get('tabNumber')
    const tabNumber = tabNumberQuery !== null && tabNumberQuery !== '' ? parseInt(tabNumberQuery) : 0;

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        history.push({
            pathname: history.location.pathname,
            search: '?tabNumber=' + newValue
        });
    };
    return (auth.roles.indexOf(ADMIN_ROLE) !== -1) ?
        (
            <div>
                <Paper className={classes.root}>
                    <Tabs
                        value={tabNumber}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        centered
                    >
                        <Tab label="Paramétrage Système  " icon={<SettingsIcon/>}/>
                        <Tab label="Paramétrage Application" icon={<PermDataSettingIcon/>}/>
                        <Tab label="Marque éditoriale" icon={<PermDataSettingIcon/>}/>
                        <Tab label="Membres" icon={<PersonPinIcon/>}/>
                        <Tab label="Monitoring" icon={<DashboardIcon/>}/>
                    </Tabs>
                </Paper>
                <Card className={classes.cardContent}>
                    <CardContent>
                        {
                            tabNumber === 0
                            && <SystemParameter/>
                        }
                        {
                            tabNumber === 1
                            && <ApplicationParameter/>
                        }
                        {
                            tabNumber === 2
                            && <MarqueEditorialeParameter/>
                        }
                        {
                            tabNumber === 3
                            && <Members/>
                        }
                        {
                            tabNumber === 4
                            && <Monitoring/>
                        }
                    </CardContent>
                </Card>
            </div>
        ) : <Redirect to='/'/>
}
